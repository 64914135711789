import React from "react"
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal'
import Button from "../buttons/Button";
import Label from "../../containers/onboarding/supplied-faq-urls/Label";
import FaqUrls from "../../containers/onboarding/supplied-faq-urls/FaqUrls";
import { submitFaqUrls } from "../../store/onboarding/faq-urls/submit-faq-urls.actions";
import { AppState } from "../../store";

const Container = styled.div`
  position: relative;
  padding: 74px 36px 20px;
`

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  span {
    font-size: 60px;
    font-weight: 100;
    min-height: 1px;
    line-height: 20px;
  }
`

const Footer = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SaveButton = styled(Button)`
  font-size: 1rem;
`

interface Props {
  show: boolean,
  onClose: () => void,
}
export default ({show, onClose}: Props) => {
  const dispatch = useDispatch();
  const faqUrls = useSelector((state: AppState) => state.manageFaqUrlsReducer.faqUrls);
  const businessId = useSelector((state: AppState) => state.businessReducer.business.id);
  const handleSaveBtnClick = () => {
    dispatch(submitFaqUrls(faqUrls, businessId));
    onClose();
  }

  return (
    <Modal centered show={show} onHide={onClose}>
      <Container>
        <CloseButton type="button" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </CloseButton>
        <div>
          <Label />
          <FaqUrls />
        </div>
        <Footer>
          <SaveButton onClick={handleSaveBtnClick}>Add FAQs</SaveButton>
        </Footer>
      </Container>
    </Modal>
  )
}