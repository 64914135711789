import React from 'react';

import questionIcon from '@iconify/icons-simple-line-icons/question';
import styled from 'styled-components';

import { Icon } from '@iconify/react';

import GenericIconContainer from '../../../../onboarding/shared/IconContainer';
import IconPopoverContainer from '../../../../onboarding/shared/FormInputLabelIconPopoverContainer';

const LabelIconContainer = styled.div`
  text-align: right;
  position: relative;
  cursor: pointer;
`;

const IconContainer = styled(GenericIconContainer)`
  svg {
    vertical-align: baseline;
  }
`;

interface Props {
  text: string;
}

export default ({text}: Props) => (
  <LabelIconContainer>
    <IconContainer><Icon icon={questionIcon}/></IconContainer>
    <IconPopoverContainer>
      <span>{text}</span>
    </IconPopoverContainer>
  </LabelIconContainer>
);
