import styled from "styled-components";
import {whiteButtonStyles} from "../../../../../shared/buttons/WhiteButton";

const Button = styled.button`
  ${whiteButtonStyles};
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -.2px;
  padding: 0 3em;
  display: inline-flex;
  align-items: center;
`;

export default Button;
