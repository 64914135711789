import React from 'react';
import styled from 'styled-components';

import UploadCrossBlue from '../../assets/images/upload-cross-blue.png';
import media from "../../styles/media";
import { palette } from "../../styles/variables";

const UploadContentDiv = styled.div`
    ${media.xxxl`
        padding: 36px 20px;
    `}
`;

const UploadContentIcon = styled.i`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 20px;
`;

const UploadContentParagraph = styled.p`
  font-family: 'Hind';
  font-size: 15px;
  color: ${palette.darkBlue};
  margin-bottom: 10px;
  letter-spacing: 0;
`;

const UploadContentA = styled.a`
  &:hover, &:focus {
      text-decoration: none;
      color: ${palette.blue};
  }

  font-weight: 500;
  color: ${palette.blue};
  font-size: 12px;
  margin-top: 20px;
  display: inline-block;
`;

const UploadContentSpan = styled.span`
  color: ${palette.pink};
`;

export default () => (
  <UploadContentDiv>
    <UploadContentIcon><img src={UploadCrossBlue} alt='icon plus blue for upload'/></UploadContentIcon>
    <UploadContentParagraph>Drag file here</UploadContentParagraph>
    <UploadContentParagraph><UploadContentSpan>.pdf files accepted</UploadContentSpan></UploadContentParagraph>
    <UploadContentA href='#/'>Click here to select file</UploadContentA>
  </UploadContentDiv>
);
