import React, { useState } from "react"
import { useSelector } from "react-redux"
import Modal from "react-modal"
import Swal from 'sweetalert2';
import styled from "styled-components"
import { palette } from "../../styles/variables"
import Button from "../../shared/buttons/Button"
import SharedInput from "../../shared/inputs/Input"
import { sendSalesEmail } from "../../store/billing/billing.actions";
import { AppState } from "../../store";

const CloseButton = styled.button`
  position: absolute;
  top: 3px;
  right: 5px;
  border: none;
  background: transparent;
  font-size: 36px;
  font-weight: 300;
`

const Label = styled.label`
  margin-bottom: 30px;
  color: ${palette.darkBlue};
`

const Container = styled.div`
  position: relative;
  width: 650px;
  padding: 30px;
`

const FormControl = styled.div`
  margin-bottom: 16px;
  label {
    margin-bottom: 5px;
  }
`

const TextArea = styled.textarea`
  min-height: 150px;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
  color: ${palette.black};
  background: ${palette.lightGray};
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 5px;
`;

const Input = styled(SharedInput)`
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
`

const Center = styled.div`
  display: block;
  margin-top: 20px;
  text-align: center;

  p {
    margin-top: 5px;
    font-size: 14px;
  }
`

interface Props {
  isOpen: boolean,
  onHide: () => void,
}

export default ({ isOpen, onHide }: Props) => {
  const businessId = useSelector<AppState, string>(state => state.businessReducer.business.id as string);
  const email = useSelector<AppState, string>(state => state.account.profile.email as string);
  const [values, setValues] = useState({
    email: email,
    message: 'I am interested in learning more about Zammo\'s Enterprise Ultimate plan.'
  });

  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [name]: e.target.value
    })
  }

  const handleSend = () => {
    sendSalesEmail(businessId, values.email, values.message).then(() => {
      onHide && onHide();    
      Swal.fire(
        'Thank you!',
        'Your email has been sent to our Enterprise Sales team and one of our voice technology consultants will be in touch with you very soon.',
        'success'
      )
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      className="zammo-modal zammo-modal-extra-top"
      overlayClassName="zammo-modal-overlay"
    >
      <Container>
        <CloseButton aria-label="Close" onClick={onHide}><span aria-hidden="true">&times;</span></CloseButton>
        <Label>CONTACT A ZAMMO ENTERPRISE CONSULTANT</Label>
        <FormControl>
          <label>My Email:</label>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange('email')}
          />
        </FormControl>
        <FormControl>
          <label>Message:</label>
          <TextArea
            value={values.message}
            onChange={(e) => {
              setValues({
                ...values,
                message: e.target.value
              })
            }}
          />
        </FormControl>
        <Center>
          <Button type="button" onClick={handleSend} style={{ width: '100%', justifyContent: 'center' }}>Contact Zammo</Button>
          <p>We will get back to you ASAP</p>
        </Center>
      </Container>
    </Modal>
  )
}
