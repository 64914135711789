import styled from 'styled-components';

import { palette } from '../../../styles/variables';

export default styled.span`
  color: ${palette.gray1};
  font-size: 12px;
  margin-top: 3px;
  display: block;
  font-family: Hind;
`;
