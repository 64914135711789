import React, { useEffect, useMemo, useCallback } from 'react';
import { selectBusiness } from '../../store/business/business.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, setNotificationRead } from '../../store/navbar/notifications/notifications.actions';
import { AppState } from '../../store';
import { getColorFromType, getIconFromType } from '../../shared/layouts/navbar-components/notifications/Notification';
import { Icon } from '@iconify/react';
import ReactTimeAgo from 'react-timeago';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { ActionButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import Skeleton from 'react-loading-skeleton';
import { Pagination } from '@bfc/zammo/lib/ui-components/Pagination';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  containerStyles,
  headerStyles,
  notificationContainerStyles,
  skeletonNotificationContainerStyles,
  timeAgoStyles,
  paginationStyles,
} from './styles';
import Breadcrumb from '../../shared/breadcrumb';

const Notifications = () => {
  const dispatch = useDispatch();
  const business = useSelector(selectBusiness);
  const notifications = useSelector((state: AppState) => state.notificationsReducer.notifications);
  const pagination = useSelector((state: AppState) => state.notificationsReducer.pagination);
  const isGettingNotifications = useSelector((state: AppState) => state.notificationsReducer.isGettingNotifications);

  const isAllRead = useMemo(() => {
    return notifications.every((notification) => notification.viewedAt);
  }, [notifications]);

  const totalPageCount = useMemo(() => {
    if (!pagination) {
      return 0;
    }
    return Math.ceil(pagination.count / pagination.limit);
  }, [pagination]);

  useEffect(() => {
    if (business?.id) {
      dispatch(getNotifications(business.id));
    }
  }, [dispatch, business]);

  const handleChangePage = useCallback(
    (newPage: number) => {
      if (business?.id) {
        dispatch(getNotifications(business.id, false, newPage));
      }
    },
    [dispatch, business]
  );

  const handleReadNotification = useCallback(
    (notificationId: string) => {
      dispatch(setNotificationRead(notificationId));
    },
    [dispatch]
  );

  const handleMarkAllAsRead = useCallback(() => {
    notifications
      .filter((notification) => !notification.viewedAt)
      .forEach((notification) => {
        dispatch(setNotificationRead(notification.id));
      });
  }, [dispatch, notifications]);

  return (
    <div>
      <Breadcrumb path="Notifications" />
      <Stack tokens={{ childrenGap: 8 }} styles={containerStyles}>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={headerStyles}>
          <StackItem>
            <Text variant="xLarge">Notifications</Text>
          </StackItem>
          <StackItem>
            {!isGettingNotifications && !isAllRead && (
              <ActionButton onClick={handleMarkAllAsRead}>Mark all as read</ActionButton>
            )}
          </StackItem>
        </Stack>
        {isGettingNotifications &&
          new Array(5).fill(0).map((_, index) => (
            <Stack key={index} horizontal tokens={{ childrenGap: 16 }} styles={skeletonNotificationContainerStyles}>
              <StackItem>
                <Skeleton circle width={20} height={20} />
              </StackItem>
              <Stack grow={1} tokens={{ childrenGap: 8 }}>
                <Skeleton width="50%" height={20} />
                <Skeleton height={20} />
              </Stack>
            </Stack>
          ))}
        {!isGettingNotifications && notifications.length === 0 && (
          <Text variant="large">There are no notifications available for your business</Text>
        )}
        {!isGettingNotifications &&
          notifications.map(({ id, status, title, description, lastUpdateTime, viewedAt }) => (
            <Stack key={id} horizontal tokens={{ childrenGap: 16 }} styles={notificationContainerStyles}>
              <StackItem>
                <Icon icon={getIconFromType(status)} width={20} color={getColorFromType(status)} />
              </StackItem>
              <Stack tokens={{ childrenGap: 8 }} grow={1}>
                <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                  <StackItem>
                    <Text styles={{ root: { color: getColorFromType(status), fontWeight: 700 } }}>{title}</Text>
                  </StackItem>
                  <StackItem styles={timeAgoStyles}>
                    <ReactTimeAgo date={lastUpdateTime} />
                  </StackItem>
                  {!viewedAt && (
                    <StackItem styles={{ root: { marginLeft: 'auto !important' } }}>
                      <TooltipHost content="Mark as read">
                        <IconButton
                          iconProps={{ iconName: 'LocationDot' }}
                          size={20}
                          onClick={() => handleReadNotification(id)}
                        />
                      </TooltipHost>
                    </StackItem>
                  )}
                </Stack>
                <StackItem>{description}</StackItem>
              </Stack>
            </Stack>
          ))}
        {pagination && totalPageCount > 1 && (
          <Stack styles={paginationStyles} horizontalAlign="center">
            <Pagination
              disabled={isGettingNotifications}
              pageCount={totalPageCount}
              defaultPage={1}
              onChange={handleChangePage}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default Notifications;
