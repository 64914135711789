import React from "react";
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components";
import moment from "moment";
import { palette } from "../../styles/variables";
import { PaymentMethod } from "../../store/billing/billing.types";
import TextButton from "../../shared/buttons/TextButton";
import visa from "../../assets/images/visa.png"
import discover from "../../assets/images/discover.png"
import mastercard from "../../assets/images/mastercard.png"
import americanexpress from "../../assets/images/americanexpress.png"
import creditcard from "../../assets/images/creditcard.png"
import bank from "../../assets/images/bank.png"
import Utils from "../../lib/Utils";
import { AppState } from "../../store";
import { setDefaultPaymentMethod } from "../../store/billing/billing.actions"

type ContainerProps = {
  active: boolean;
}
const Container = styled.div<ContainerProps>`
  background: white;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 3px solid ${props => props.active ? palette.blue : palette.white};
  width: 300px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 21px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;

const Button = styled(TextButton)`
  font-size: 12px;
  text-transform: uppercase;
  padding: 0;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  span {
    color: ${palette.blue};
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

const Description = styled.div`
  p {
    font-weight: 500;
    color: ${palette.black};
  }
`

const Logo = styled.img`
  height: 35px;
  width: auto;
`

type Props = {
  method: PaymentMethod;
};

export default ({ method }: Props) => {
  const dispatch = useDispatch()
  const businessId = useSelector<AppState, string>(state => state.businessReducer.business.id as string);
  const handleMakePrimaryClick = () => {
    Utils
      .showConfirmPopup({ title: '', text: 'Do you want to set this method as the default payment?'})
      .then(results => {
        if (results) {
          dispatch(setDefaultPaymentMethod(businessId, method.chargebeePaymentMethodId))
        }
      })
  }

  let description;
  if (method.frequency === 'monthly') {
    description = 'Monthly Subscription Auto'
  } else if (method.frequency === 'yearly') {
    description = 'Annual Subscription Auto'
  }

  let image;
  if (method.paymentType === 'card') {
    if (method.name === 'Visa') {
      image = visa;
    } else if (method.name === 'Discover') {
      image = discover;
    } else if (method.name === 'AmericanExpress') {
      image = americanexpress;
    } else if (method.name === 'MasterCard') {
      image = mastercard;
    } else if (method.name === 'Jcb') {
      image = mastercard;
    } else {
      image = creditcard;
    }
  } else {
    image = bank;
  }
  
  return (
    <Container active={method.isRecurringPaymentMethod}>
      <Heading>
        <span>
          {method.name} Ending {method.last4}
        </span>
        <Logo src={image} alt={method.name} />
      </Heading>
      <div>
        <Description>
          {description && <p>{description}</p>}
          {method.renewsAt && <p>Renews on {moment(method.renewsAt).format('MMM DD, YYYY')}</p>}
        </Description>
        <Actions>
          {method.isRecurringPaymentMethod ? (
            <span>PRIMARY CARD</span>
          ) : (
            <Button activeColor onClick={handleMakePrimaryClick}>MAKE PRIMARY</Button>
          )}
        </Actions>
      </div>
    </Container>
  );
};
