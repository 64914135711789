import React from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';

import FaqUrlInput from './FaqUrlInput';

import { FaqUrl } from '../../../store/onboarding/faq-urls/manage-faq-urls.types';
import { AppState } from '../../../store';

import media from '../../../styles/media';

import { palette } from '../../../styles/variables';
import { createFaqUrl } from '../../../store/onboarding/faq-urls/create-faq-url.actions';

const AddLinkContainer = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const AddLink = styled.span`
  color: ${palette.mediumBlue};
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${media.md`
    font-size: 18px;
  `}

`;

interface Props {
  faqUrls: FaqUrl[];
  createFaqUrl: typeof createFaqUrl;
};

const mapStateToProps = (state: AppState) => ({
  faqUrls: state.manageFaqUrlsReducer.faqUrls,
});

const mapDispatchToProps = {
  createFaqUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(({ faqUrls, createFaqUrl }: Props) => (
  <>
    {faqUrls.map((faqUrl, idx) => <FaqUrlInput key={faqUrl.id} uid={faqUrl.id} url={faqUrl.url} isRemovable={faqUrls.length > 1} />)}
    <AddLinkContainer><AddLink onClick={(e) => {e.preventDefault(); createFaqUrl();}}>+ Add another FAQ link</AddLink></AddLinkContainer>
  </>
));
