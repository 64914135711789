import styled from 'styled-components';

import { palette } from '../../../styles/variables';
import media from '../../../styles/media';

export default styled.span`
  font-weight: 500;
  color: ${palette.pink};
  letter-spacing: -.2px;
  outline: none;

  ${media.xxxl`
    font-size: 13px;
  `}
`;
