import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import pathOr from 'ramda/src/pathOr';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import Breadcrumb from '../../shared/breadcrumb';
import Container from '../../shared/layouts/Container';
import Label from '../../shared/Label';
import Input from '../../shared/inputs/Input';
import ImageUploadInput from '../../shared/ImageUploadInput';
import { AppState } from '../../store';
import { updateUserProfileRequest, resendEmailVerification } from '../../store/account/actions';
import Button from '../../shared/buttons/Button';
import { UserProfile } from '../../store/account/types';
import { palette } from '../../styles/variables';
import Utils from '../../lib/Utils';
import { adb2c } from '../../lib/ADB2C';
import TextButton from '../../shared/buttons/TextButton';
import Toggle from '../../shared/Toggle';

const Wrapper = styled.div`
  max-width: 790px;
  width: 100%;
  margin: auto;
`;

const FormContainer = styled.div`
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 44px 47px 40px;
  width: 100%;
  margin-top: 30px;
`;

const FormInputContainer = styled.div`
  margin-bottom: 30px;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Error = styled.div`
  font-size: 14px;
  color: ${palette.red};
  margin-top: 5px;
`;

const ResetPasswordLink = styled.a`
  color: ${palette.blue};
`;

const VerificationEmailBtn = styled(TextButton)`
  font-size: 16px;
`;

type Props = {
  profile: UserProfile;
  updateUserProfileRequest: (profile: UserProfile) => void;
}

type Error = {
  firstName?: string;
  lastName?: string;
  email?: string;
  photo?: string;
}

type ProfileState = {
  emailIsVerified: boolean,
  canResetPassword: boolean,
}

const Profile = ({ profile, updateUserProfileRequest }: Props) => {
  const dispatch = useDispatch<ThunkDispatch<Promise<void>, any, AnyAction>>();
  const { emailIsVerified, canResetPassword } = useSelector<AppState, ProfileState>((state) => ({
    emailIsVerified: state.account.profile.emailIsVerified || false,
    canResetPassword: state.account.profile.canResetPassword || false,
  }));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [values, setValues] = useState<UserProfile>({
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
    email: profile.email || '',
    jobTitle: profile.jobTitle || '',
    photoUrl: profile.photoUrl,
    instructionalVideosEnabled: profile.instructionalVideosEnabled,
    photoFile: undefined,
  });

  const [errors, setErrors] = useState<Error>({
    firstName: '',
    lastName: '',
    email: '',
    photo: '',
  });

  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>();

  useEffect(() => {
    setResetPasswordUrl(adb2c.resetPasswordUrl);
  }, []);

  const handleChange = (name: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (isSubmitted) {
      let error;
      if (name === 'firstName' || name === 'lastName') {
        error = values.firstName ? '' : 'This field is required.';
      } else if (name === 'email') {
        error = values.email ? (Utils.validateEmail(values.email) ? '' : 'A valid email is required.') : 'This field is required.';
      }
      setErrors({
        ...errors,
        [name]: error,
      });
    }
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  const handleResendVerificationEmail = () => {
    Utils.showInfoLoadingWithText('Resending verification email');
    dispatch(resendEmailVerification())
      .then(() => {
        Swal.fire(
          '',
          `An email with the verification link has been sent to ${values.email}`,
          'success',
        );
      })
      .catch(() => {
        Swal.close();
      });
  };

  const handleFileDrop = (files?: File[]) => {
    if (files) {
      setValues({
        ...values,
        photoFile: files[0],
        photoUrl: '',
      });
      if (isSubmitted) {
        setErrors({
          ...errors,
          photo: '',
        });
      }
    } else {
      setValues({
        ...values,
        photoFile: undefined,
        photoUrl: '',
      });
      if (isSubmitted) {
        setErrors({
          ...errors,
          photo: 'This field is required.',
        });
      }
    }
  };

  const handleUpdateMyProfile = () => {
    const eFirstName = values.firstName ? '' : 'This field is required.';
    const eLastName = values.lastName ? '' : 'This field is required.';
    const eEmail = values.email ? (Utils.validateEmail(values.email) ? '' : 'Invalid Email') : 'This field is required.';
    const ePhoto = (values.photoFile || values.photoUrl) ? '' : 'This field is required.';
    setErrors({
      ...errors,
      firstName: eFirstName,
      lastName: eLastName,
      email: eEmail,
      photo: ePhoto,
    });

    if (eFirstName || eLastName || eEmail || ePhoto) {
      setIsSubmitted(true);
    } else {
      updateUserProfileRequest({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        jobTitle: values.jobTitle,
        photoFile: values.photoFile,
        instructionalVideosEnabled: values.instructionalVideosEnabled,
      });
    }
  };

  return (
    <>
      <Breadcrumb path="MY PROFILE" />
      <Container>
        <Wrapper>
          <h2>Update My Profile</h2>
          <FormContainer>
            <Row>
              <Col>
                <FormInputContainer>
                  <Label>
                    First Name
                    {' '}
                    <span>*</span>
                  </Label>
                  <Input type="text" value={values.firstName} onChange={handleChange('firstName')} />
                  <Error>{errors.firstName}</Error>
                </FormInputContainer>
              </Col>
              <Col>
                <FormInputContainer>
                  <Label>
                    Last Name
                    {' '}
                    <span>*</span>
                  </Label>
                  <Input type="text" value={values.lastName} onChange={handleChange('lastName')} />
                  <Error>{errors.lastName}</Error>
                </FormInputContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputContainer>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Label>
                      My Email Address
                      {' '}
                      <span>*</span>
                    </Label>
                    {!emailIsVerified && canResetPassword && <VerificationEmailBtn activeColor onClick={handleResendVerificationEmail}>Resend verification email</VerificationEmailBtn>}
                  </div>
                  <Input type="text" value={values.email} onChange={handleChange('email')} disabled={!canResetPassword} />
                  <Error>{errors.email}</Error>
                </FormInputContainer>
              </Col>
            </Row>
            {canResetPassword && (
            <Row>
              <Col>
                <FormInputContainer>
                  <Label>
                    Password
                    {' '}
                    <span>*</span>
                  </Label>
                  <ResetPasswordLink href={resetPasswordUrl}>Change Password</ResetPasswordLink>
                </FormInputContainer>
              </Col>
            </Row>
            )}
            <Row>
              <Col>
                <FormInputContainer>
                  <Label>Job Title</Label>
                  <Input type="text" value={values.jobTitle} onChange={handleChange('jobTitle')} />
                </FormInputContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormInputContainer>
                  <Label>
                    My Profile Photo
                    {' '}
                    <span>*</span>
                  </Label>
                  <ImageUploadInput
                    imageFile={values.photoFile}
                    imageUrl={values.photoUrl}
                    onFilesDrop={handleFileDrop}
                    onRemove={() => handleFileDrop()}
                  />
                  <Error>{errors.photo}</Error>
                </FormInputContainer>
              </Col>
            </Row>
            <FormInputContainer
              style={{ display: "flex", alignItems: "center" }}
            >
              <Label style={{ margin: "0 20px 0 0" }}>Video</Label>
              <Toggle
                id="instructional-videos"
                name="instructionalVideosEnabled"
                value={values.instructionalVideosEnabled ? "on" : "off"}
                left={{
                  value: "on",
                  component: <span>ON</span>,
                }}
                right={{
                  value: "off",
                  component: <span>OFF</span>,
                }}
                onLeftToggleClick={() =>
                  setValues({
                    ...values,
                    instructionalVideosEnabled: true,
                  })
                }
                onRightToggleClick={() =>
                  setValues({
                    ...values,
                    instructionalVideosEnabled: false,
                  })
                }
              />
            </FormInputContainer>
            <FormFooter>
              <Button onClick={handleUpdateMyProfile}>Update My Profile</Button>
            </FormFooter>
          </FormContainer>
        </Wrapper>
      </Container>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  profile: pathOr(undefined, ['account', 'profile'], state) as UserProfile,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
) => ({
  updateUserProfileRequest: (profile: UserProfile) => dispatch(updateUserProfileRequest(profile)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
