import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
    GetIndustriesActionType,
    Industry,
    GET_INDUSTRIES_FAILURE,
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_SUCCESS,
} from './get-industries.types';
import ADB2C from '../../lib/ADB2C';
import Logger from '../../lib/Logger';
import Utils from '../../lib/Utils';

const logger = new Logger('get-industries.actions');

const getIndustriesRequest = (): GetIndustriesActionType => {
    return {
        type: GET_INDUSTRIES_REQUEST
    }
};

export const getIndustriesSuccess = (industries: Industry[]): GetIndustriesActionType => {
    return {
        type: GET_INDUSTRIES_SUCCESS,
        industries: industries,
    }
};

const getIndustriesFailure = (reason: string): GetIndustriesActionType => {
    return {
        type: GET_INDUSTRIES_FAILURE,
        reason: reason,
    }
};

const getIndustries = (adb2c: ADB2C): ThunkAction<Promise<void>, {}, {}, GetIndustriesActionType> => {
    return (dispatch: ThunkDispatch<{}, {}, GetIndustriesActionType>): Promise<void> => {
        dispatch(getIndustriesRequest());
        return new Promise<void>(() => {
            const hellojsApiConf = {
                'path': `/api/industries`,
                'method': 'get',
            };

            adb2c.makeAuthenticatedApi(hellojsApiConf)
            .then(
                (fetchRes: any) => {
                    logger.log('getIndustries::', 'fetchRes', fetchRes);
                    if (Utils.isError(fetchRes)) {
                        dispatch(getIndustriesFailure(fetchRes.message));
                    } else {
                        dispatch(getIndustriesSuccess(fetchRes));
                    }
                },
                (fetchError: any) => {
                    logger.log('getIndustries::', 'fetchError', fetchError);
                    dispatch(getIndustriesFailure(fetchError.message));
                }
            );
        });
    };
};

export { getIndustries };
