import Fact from "../../lib/Fact";

export const GET_INDUSTRIES_REQUEST: string = 'GET_INDUSTRIES_REQUEST';
export const GET_INDUSTRIES_SUCCESS: string = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAILURE: string = 'GET_INDUSTRIES_FAILURE';

export type SubIndustry = {
  id: string;
  industryId: string;
  name: string;
  questionStrings?: any;
  questions?: any;
}

export type Industry = {
    id: string;
    name: string;
    subIndustries: Array<SubIndustry>;
};

export type GetIndustriesState = {
    industries: Industry[];
    isGettingIndustries: boolean;
    hasLoaded: boolean;
    fact: Fact | undefined;
}

export type GetIndustriesRequestAction = {
    type: typeof GET_INDUSTRIES_REQUEST;
};

export type GetIndustriesSuccessAction = {
    type: typeof GET_INDUSTRIES_SUCCESS;
    industries: Industry[];
};

export type GetIndustriesFailureAction = {
    type: typeof GET_INDUSTRIES_FAILURE;
    reason: string;
};

export type GetIndustriesActionType = GetIndustriesRequestAction | GetIndustriesSuccessAction | GetIndustriesFailureAction;
