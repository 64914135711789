import React from 'react';
import styled from 'styled-components';

import GenericLabel from '../../../../../shared/Label';
import MandatorySpan from './MandatorySpan';

export const Label = styled(GenericLabel)`
  font-size: 12px;
`;

interface Props {
  text: string;
  required?: boolean;
}

export default ({ text, required }: Props) => (
  <Label>{text} {required !== undefined && required && <MandatorySpan>*</MandatorySpan>}</Label>
);
