import React from "react";
import Modal from "react-bootstrap/Modal";

import pathOr from 'ramda/src/pathOr';
import queryString from "query-string";
import styled from "styled-components";

import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import InvitationVerifyModalContent from "./InvitationVerifyModalContent";
import Utils from "../../../lib/Utils";
import Logger from "../../../lib/Logger";

import { GET_USER_INVITE_FAILURE, ACTIVATE_INVITE_FAILURE, ACTIVATE_INVITE_SUCCESS, USER_INVITE_ACCEPTED } from "../../../store/team-member/team-member.types";

import { AppState } from "../../../store";

import { getUserInvite, activateInvitation, setInvitationAcceptance } from "../../../store/team-member/team-member.actions";

const Container = styled.div`
  position: relative;
  padding: 74px 36px 20px;
`;

const logger = new Logger('APIUtils');

const isCompletionFact = (factDescription: string) => [GET_USER_INVITE_FAILURE, ACTIVATE_INVITE_FAILURE, ACTIVATE_INVITE_SUCCESS, USER_INVITE_ACCEPTED].indexOf(factDescription) !== -1;

const getTitleFromUserInviteFact = (factDescription: string, inviteCode: string, businessName: string) => {
  switch (factDescription) {
    case GET_USER_INVITE_FAILURE:
      return `Sorry, we could not find an active invitation with ${inviteCode ? `the code ${inviteCode}` : 'that code'}.`;
    case ACTIVATE_INVITE_FAILURE:
      return 'Something went wrong with the activation. Our team has been notified and will be in touch soon.';
    case USER_INVITE_ACCEPTED:
        return 'This invitation has already been accepted.';
    default:
      return `You have successfully joined ${businessName} team.`;
  }
}

export default () => {
  const search = useSelector((state: AppState) => state.router.location.search) as string;
  const userInvite = useSelector((state: AppState) => state.teamMember.userInvite);
  const userInviteFact = useSelector((state: AppState) => state.teamMember.fact);
  const queryParameters = queryString.parse(search);
  const inviteCode  = queryParameters.code as string;
  const dispatch = useDispatch();
  const businessName = pathOr('', ['businessName'], userInvite ? userInvite : {}) as string;

  useEffect(() => {
    dispatch(getUserInvite(inviteCode));
  }, [dispatch, inviteCode]);

  const handleAccept = () => {
    logger.log('handleAccept');
    Utils.showInfoLoadingWithText('Adding you to the team');
    dispatch(activateInvitation(inviteCode));
  }

  const handleOk = () => {
    dispatch(setInvitationAcceptance());
    dispatch(push('/app/dashboard'));
  }

  return (
    <Modal show={true} onHide={() => {}} centered size="lg">
      <Container>
        {!userInviteFact && userInvite && 
          <InvitationVerifyModalContent
          title={`You have been invited to join ${businessName} on Zammo as an ${userInvite.role.name}.`}
          textButtonLabel="Not for the moment"
          acceptButtonLabel="Accept"
          onAcceptClick={handleAccept}
          onTexButtonClick={handleOk} />
        }
        {userInviteFact && isCompletionFact(userInviteFact.description) &&
          <InvitationVerifyModalContent
          title={getTitleFromUserInviteFact(userInviteFact.description, inviteCode, businessName)}
          onAcceptClick={handleOk} />
        }
      </Container>
    </Modal>
  );
}
