class Logger {
    moduleName: string;

    constructor(moduleName:string) {
        this.moduleName = moduleName;
    }

    log(...args: any[]) {
        if (process.env.NODE_ENV === 'development') {
            console.log(this.moduleName, ...args);
        }
    }
}

export default Logger;