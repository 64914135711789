import {
  AdvancedOrganizationSettingsState,
  getAdvancedOrganizationSettingsSuccessAction,
  organizationSettingsActions,
  OrganizationSettingsActionTypes,
} from './organization-settings.types';

const initialState: AdvancedOrganizationSettingsState = {
  settings: undefined,
};

const advancedOrganizationSettingsReducer = (
  state: AdvancedOrganizationSettingsState = initialState,
  action: organizationSettingsActions
): AdvancedOrganizationSettingsState => {
  switch (action.type) {
    case OrganizationSettingsActionTypes.GET_ADVANCED_ORGANIZATION_SETTINGS_SUCCESS:
      const getSettingsAction = action as getAdvancedOrganizationSettingsSuccessAction;
      return {
        ...state,
        settings: getSettingsAction.advancedOrganizationSettings,
      };

    default:
      return state;
  }
};

export { advancedOrganizationSettingsReducer };
