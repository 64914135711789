import Fact from "../../../lib/Fact";

export const CREATE_ENTERPRISE_QNA: string = 'CREATE_ENTERPRISE_QNA';
export const UPDATE_ENTERPRISE_QNA: string = 'UPDATE_ENTERPRISE_QNA';
export const UPDATE_ENTERPRISE_QNAS: string = 'UPDATE_ENTERPRISE_QNAS';
export const DELETE_ENTERPRISE_QNA: string = 'DELETE_ENTERPRISE_QNA';

export const SUBMIT_ENTERPRISE_QNAS_REQUEST: string = 'SUBMIT_ENTERPRISE_QNAS_REQUEST';
export const SUBMIT_ENTERPRISE_QNAS_SUCCESS: string = 'SUBMIT_ENTERPRISE_QNAS_SUCCESS';
export const SUBMIT_ENTERPRISE_QNAS_FAILURE: string = 'SUBMIT_ENTERPRISE_QNAS_FAILURE';

export type ManageEnterpriseQnaState = {
    enterpriseQnas: EnterpriseQna[];
    isSubmittingEnterpriseQnas: boolean;
    fact: Fact | undefined;
};

export type EnterpriseQna = {
    id: string;
    isIndustry: boolean;
    question: string;
    answer: string;
};

export type CreateEnterpriseQnaAction = {
    type: typeof CREATE_ENTERPRISE_QNA,
    question: string,
    answer: string,
};

export type UpdateEnterpriseQnaAction = {
    type: typeof UPDATE_ENTERPRISE_QNA,
    id: string,
    question: string,
    answer: string,
};

export type UpdateEnterpriseQnasAction = {
    type: typeof UPDATE_ENTERPRISE_QNA,
    enterpriseQnas: EnterpriseQna[];
};

export type DeleteEnterpriseQnaAction = {
    type: typeof DELETE_ENTERPRISE_QNA,
    id: string;
};

export type SumitEnterpriseQnasRequestAction = {
    type: typeof SUBMIT_ENTERPRISE_QNAS_REQUEST;
};

export type SumitEnterpriseQnasSuccessAction = {
    type: typeof SUBMIT_ENTERPRISE_QNAS_SUCCESS;
};

export type SumitEnterpriseQnasFailureAction = {
    type: typeof SUBMIT_ENTERPRISE_QNAS_FAILURE;
    reason: string;
};

export type ManageEnterpriseQnasAction =
    CreateEnterpriseQnaAction
    | UpdateEnterpriseQnaAction
    | UpdateEnterpriseQnasAction
    | DeleteEnterpriseQnaAction
    | SumitEnterpriseQnasRequestAction
    | SumitEnterpriseQnasSuccessAction
    | SumitEnterpriseQnasFailureAction;
