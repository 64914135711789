import React from "react";
import styled from "styled-components";
import NavbarLeft from "./NavbarLeft";
import { palette } from "../../styles/variables";

const Navbar = styled.nav`
  background: ${palette.darkBlue};
  padding: 0;
  display: inline-flex;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;


export default () => (
  <Navbar>
    <NavbarLeft />
  </Navbar>
);
