import styled from 'styled-components';

import media from '../../../styles/media';

import { palette } from '../../../styles/variables';

export default styled.button`
  display: inline-flex;
  align-items: center;
  background: ${ palette.mediumBlue };
  border-radius: 2em;
  line-height: 2em;
  color: white;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -.2px;
  padding: 0 3em;
  padding: 0 3em;
  transition: all .5s ease-in-out 0s;

  &:hover {
    background: ${ palette.lightBlue };
    color: white;
  }

  &:focus {
    outline: none;
  }

  svg {
    font-size: 16px;
    margin-left: 10px;
  }

  ${media.md`
    font-size: 18px;
    line-height: 40px;
  `}

  ${media.md`
    font-size: 18px;
    line-height: 40px;
  `}

  ${media.lg`
    font-size: 18px;
    padding: 0 30px;
  `}
`;
