import React from "react";
import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";
import closeIcon from "@iconify/icons-simple-line-icons/close";

import { Container, CloseIcon } from "./styles";

interface Props {
  url: string;
  onClose: () => void;
  style?: React.CSSProperties,
}

export default ({ url, onClose, style }: Props) => (
  <Container style={style}>
    <CloseIcon onClick={onClose}>
      <Icon icon={closeIcon} />
    </CloseIcon>
    <ReactPlayer url={url} width={260} height={146} controls playing />
  </Container>
);
