import styled from 'styled-components';

import media from "../../../styles/media";
import { palette } from '../../../styles/variables';

export default styled.input`
  font-size: 20px;
  font-weight: 400;
  color: ${palette.black};
  height: 46px;
  padding: 8px 20px;
  background: ${palette.lightGray};
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 5px;
  transition: all .5s ease-in-out 0s;
  outline: none;
  resize: none;
  text-decoration: none;
  margin: 0;
  overflow: visible;

  ${media.xlg`
    font-size: 16px;
  `}

  ${media.md`
    font-size: 14px;
  `}
`;
