export const POST_CONNECT_CONVERSATIONAL_MODULE_REQUEST = "POST_CONNECT_CONVERSATIONAL_MODULE_REQUEST";
export const POST_CONNECT_CONVERSATIONAL_MODULE_SUCCESS = "POST_CONNECT_CONVERSATIONAL_MODULE_SUCCESS";
export const POST_CONNECT_CONVERSATIONAL_MODULE_FAILURE = "POST_CONNECT_CONVERSATIONAL_MODULE_FAILURE";
export const GET_CONNECTIONS_CONVERSATIONAL_MODULE_REQUEST = "GET_CONNECTIONS_CONVERSATIONAL_MODULE_REQUEST";
export const GET_CONNECTIONS_CONVERSATIONAL_MODULE_SUCCESS = "GET_CONNECTIONS_CONVERSATIONAL_MODULE_SUCCESS";
export const GET_CONNECTIONS_CONVERSATIONAL_MODULE_FAILURE  = "GET_CONNECTIONS_CONVERSATIONAL_MODULE_FAILURE";
export const GET_CONVERSATIONAL_METADATA_REQUEST = "GET_CONVERSATIONAL_METADATA_REQUEST";
export const GET_CONVERSATIONAL_METADATA_SUCCESS = "GET_CONVERSATIONAL_METADATA_SUCCESS";
export const GET_CONVERSATIONAL_METADATA_FAILURE  = "GET_CONVERSATIONAL_METADATA_FAILURE";

export type ConversationModuleConfiguration = {
  moduleName: string;
  businessId: string;
  connectionId: string;
  configuration: any
};

export type ConversationalModuleConnection = {
  businessId: string;
  name: string;
  isConnected: boolean;
  title: string;
  subTitle: string;
  onboardingPage: string;
  description: string;
};

export type RequestFailureAction = {
  type: string;
};

export type PostConnectConversationalModuleSuccessAction = {
  type: typeof POST_CONNECT_CONVERSATIONAL_MODULE_SUCCESS;
};

export type GetConnectionsConversationalModuleSuccessAction = {
  type: typeof GET_CONNECTIONS_CONVERSATIONAL_MODULE_SUCCESS;
  connections: ConversationalModuleConnection[];
};

export type GetConversationMetadataSuccessAction = {
  type: typeof GET_CONVERSATIONAL_METADATA_SUCCESS;
  currentModuleConfig: ConversationModuleConfiguration;
};

export type ConversationalModuleActions =
  | PostConnectConversationalModuleSuccessAction
  | GetConnectionsConversationalModuleSuccessAction
  | GetConversationMetadataSuccessAction
  | RequestFailureAction;

export type ConversationalModuleState = {
  connections: ConversationalModuleConnection[],
  currentModuleConfig: ConversationModuleConfiguration
};
