import React, { ReactNode } from "react";
import styled from "styled-components";
import { palette } from "../../styles/variables";
import { Link } from "react-router-dom";

const Container = styled(Link)`
  border: 1px solid ${palette.border};
  border-radius: 4px;
  text-align: center;
  padding: 3rem 1.5rem 3rem;
  transition: all ease-in-out 0.5s;
  height: 100%;
  margin: 0 -10px;
  cursor: pointer;
  display: block;
  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.5s;
  }
`;

const Icon = styled.div`
  svg {
    font-size: 48px;
    color: ${palette.blue};
    display: block;
    margin: 0 auto 25px;
  }
`;

const H4 = styled.h4`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
`;

interface Props {
  icon: ReactNode;
  title: string;
  to: string;
}

export default ({to, icon, title}: Props) => {
  return (
    <Container to={to}>
      <Icon>{icon}</Icon>
      <H4>{title}</H4>
    </Container>
  )
}
