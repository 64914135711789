import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { FeatureFlag } from '../store/feature-flags/feature-flags.types';
import { selectFeatureFlags } from '../store/feature-flags/feature-flags.reducers';

export function useFeatureFlag(flagName: string): boolean;
export function useFeatureFlag(flagNames: string[]): boolean[];
export function useFeatureFlag(featureFlagNames: string | string[]): boolean | boolean[] {
  const featureFlags = useSelector<AppState, FeatureFlag[]>(selectFeatureFlags);

  if (!featureFlagNames) {
    return false;
  }

  if (typeof featureFlagNames === 'string') {
    return featureFlags.some((flag) => flag.name === featureFlagNames && flag.isEnabled);
  }

  return featureFlagNames.map((flagName) => featureFlags.some((flag) => flag.name === flagName && flag.isEnabled));
}
