import { ThunkDispatch } from "redux-thunk";
import Swal from "sweetalert2";
import { adb2c } from "../../lib/ADB2C";
import Utils from "../../lib/Utils";
import {
  USERPROFILE_REQUEST,
  USERPROFILE_SUCCESS,
  USERPROFILE_FAILURE,
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FAILURE,
  USERPROFILE_UPDATE_REQUEST,
  USERPROFILE_UPDATE_SUCCESS,
  USERPROFILE_UPDATE_FAILURE,
  AccountActions,
  UPDATE_PROFILE_PHOTO_FILE,
  UserProfile,
  SET_VERIFY_EMAIL,
} from "./types";

export const updateProfilePhotoFile = (photoFile?: File): AccountActions => ({
  type: UPDATE_PROFILE_PHOTO_FILE,
  photoFile
});

export const setVerifyEmail = (): AccountActions => ({
  type: SET_VERIFY_EMAIL
});

export const userProfileRequest = () => (dispatch: ThunkDispatch<{}, {}, AccountActions>) => {
  dispatch({
    type: USERPROFILE_REQUEST
  });

  const conf = {
    path: `/api/userprofile/info`,
    method: "get"
  };

  adb2c.makeAuthenticatedApi(conf)
  .then(
    (fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: USERPROFILE_FAILURE
        });
        Swal.close();
      } else {
        dispatch({
          type: USERPROFILE_SUCCESS,
          profile: fetchRes
        });
        Swal.close();
      }
    },
    () => {
      dispatch({
        type: USERPROFILE_FAILURE
      });
      Swal.close();
    }
  );
};

export const resendEmailVerification = () => (dispatch: ThunkDispatch<{}, {}, AccountActions>) => {
  dispatch({
    type: RESEND_EMAIL_VERIFICATION_REQUEST
  });

  const conf = {
    path: `/api/userprofile/resendemailverification`,
    method: "get"
  };

  return new Promise((resolve, reject) => {
    adb2c.makeAuthenticatedApi(conf)
    .then(
      (fetchRes: any) => {
        if (Utils.isError(fetchRes)) {
          dispatch({
            type: RESEND_EMAIL_VERIFICATION_FAILURE
          });
          reject();
        } else {
          dispatch({
            type: RESEND_EMAIL_VERIFICATION_SUCCESS,
          });
          resolve(fetchRes);
        }
      },
      () => {
        dispatch({
          type: RESEND_EMAIL_VERIFICATION_FAILURE
        });
        reject();
      }
    );
  })
};

export const updateUserProfileRequest = (profile: UserProfile) => (dispatch: ThunkDispatch<{}, {}, AccountActions>) => {
  Utils.showInfoLoadingWithText("Update my profile");
  dispatch({
    type: USERPROFILE_UPDATE_REQUEST
  });

  let formData = new FormData();
  profile.firstName && formData.append('FirstName', profile.firstName);
  profile.lastName && formData.append('LastName', profile.lastName);
  profile.email && formData.append('Email', profile.email);
  profile.jobTitle && formData.append('JobTitle', profile.jobTitle);
  profile.photoFile && formData.append('photoFile', profile.photoFile);
  formData.append('InstructionalVideosEnabled', profile.instructionalVideosEnabled ? 'True' : 'False');

  const conf = {
    path: `/api/userprofile/info`,
    method: "put",
    data: formData
  };

  adb2c.makeAuthenticatedApi(conf)
  .then(
    (fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: USERPROFILE_UPDATE_FAILURE
        });
        Swal.fire({
          title: 'Something went wrong',
          type: 'error',
          html: `<p>Could not proceed your request</p>`,
          confirmButtonText: 'OK',
        });
      } else {
        dispatch({
          type: USERPROFILE_UPDATE_SUCCESS,
        });        
        dispatch(userProfileRequest());
      }
    },
    () => {
      dispatch({
        type: USERPROFILE_UPDATE_FAILURE
      });
      Swal.fire({
        title: 'Something went wrong',
        type: 'error',
        html: `<p>Could not proceed your request</p>`,
        confirmButtonText: 'OK',
      });
    }
  );
};

