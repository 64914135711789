import styled, { css } from "styled-components"
import { palette } from "../../styles/variables";

export const whiteButtonStyles = css`
  font-size: 12px;
  background: none;
  border: 1px solid ${palette.blue};
  color: ${palette.blue};
  padding: 0.5rem 1rem;
  border-radius: 2em;
  &:hover {
    background: ${palette.blue};
    color: white;
    border: 1px solid transparent;
  }
`;

export const blueButtonStyles = css`
  font-size: 12px;
  background: ${palette.blue};
  border: 1px solid ${palette.white};
  color: ${palette.white};
  padding: 0.5rem 1rem;
  border-radius: 2em;
  &:hover {
    background: ${palette.white};
    color: ${palette.blue};
    border: 1px solid ${palette.blue};
  }
`;

export default styled.button`
  ${whiteButtonStyles};
`
