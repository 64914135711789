import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { adb2c } from '../../../lib/ADB2C';
import Logger from '../../../lib/Logger';
import {
  SubmitFaqUrlsAction, SUBMIT_FAQ_URLS_REQUEST, SUBMIT_FAQ_URLS_SUCCESS, SUBMIT_FAQ_URLS_FAILURE,
} from './submit-faq-urls.types';
import { FaqUrl } from './manage-faq-urls.types';
import Utils from '../../../lib/Utils';

const logger = new Logger('submit-faq-urls.actions');

const submitFaqUrlsRequest = (): SubmitFaqUrlsAction => ({
  type: SUBMIT_FAQ_URLS_REQUEST,
});

const submitFaqUrlsSuccess = (): SubmitFaqUrlsAction => ({
  type: SUBMIT_FAQ_URLS_SUCCESS,
});

const submitFaqUrlsFailure = (reason: string): SubmitFaqUrlsAction => ({
  type: SUBMIT_FAQ_URLS_FAILURE,
  reason,
});

const processFaqApiErrorRes = (errorRes: any): string => {
  if (!Utils.isObject(errorRes)) {
    return errorRes;
  }

  if ('errors' in errorRes) {
    return ('BusinessId' in errorRes.errors ? 'The business Id you supplied is invalid' : 'No valid url was supplied');
  }
  if ('message' in errorRes) {
    return errorRes.message;
  }

  return 'something wrong occured on the server, our team has been informed';
};

const submitFaqUrls = (faqUrls: FaqUrl[], businessId?: string): ThunkAction<Promise<void>, {}, {}, SubmitFaqUrlsAction> => (dispatch: ThunkDispatch<{}, {}, SubmitFaqUrlsAction>): Promise<void> => {
  dispatch(submitFaqUrlsRequest());

  return new Promise<void>(() => {
    const hellojsApiConf = {
      path: `/api/faq/post?businessId=${businessId}`,
      method: 'post',
      data: {
        FaqUrls: faqUrls.map((faqUrl) => faqUrl.url),
        BusinessId: businessId,
      },
    };

    adb2c.makeAuthenticatedApi(hellojsApiConf)
    .then(
      (fetchRes: any) => {
        logger.log('submitFaqUrls::', 'submitFaqUrls', fetchRes);
        if (Utils.isError(fetchRes)) {
          throw fetchRes;
        } else {
          dispatch(submitFaqUrlsSuccess());
          Utils.showModalTypeWithText(`The page${faqUrls.length > 1 ? 's are' : ' is'} being ingested and you will receive a confirmation upon completion.`);
        }
      },
      (fetchError: any) => {
        logger.log('submitFaqUrls::', 'fetchError', fetchError);
        const humanizedError = processFaqApiErrorRes(fetchError);
        Utils.showRequestFailedWithText(humanizedError);
        dispatch(submitFaqUrlsFailure(humanizedError));
      },
    );
  });
};

export { submitFaqUrls };
