import styled from "styled-components";

export default styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  span {
    font-size: 60px;
    font-weight: 100;
    min-height: 1px;
    line-height: 20px;
  }
`;
