import { ThunkDispatch } from 'redux-thunk';
import {
  GET_ALL_RECEIVED_INVITES_REQUEST,
  GET_ALL_RECEIVED_INVITES_FAILURE,
  GET_ALL_RECEIVED_INVITES_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
  ReceivedInvitesAction,
} from './received-invites.types';
import Utils from '../../lib/Utils';
import { adb2c } from '../../lib/ADB2C';
import Logger from '../../lib/Logger';
import { ONBOARDING_INVITE_ACCEPTED, OnboardingActionType } from '../onboarding/onboarding.types';
import { resetVoicePlatform } from '../voice-platform/voice-platform.actions';
import APIUtils from '../../lib/APIUtils';
import { setBusiness, setBusinessAsync } from '../business/business.actions';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { getBusinessesSuccess } from '../business/get-businesses.actions';
import { markAsCompleted } from '../onboarding/onboarding.actions';

const logger = new Logger('received-invites.actions');

export const getAllReceivedInvites = () => (dispatch: ThunkDispatch<{}, {}, ReceivedInvitesAction>) => {
  dispatch({
    type: GET_ALL_RECEIVED_INVITES_REQUEST,
  });

  const conf = {
    path: `/api/Invites`,
    method: 'get',
  };

  return adb2c.makeAuthenticatedApi(conf).then(
    (fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: GET_ALL_RECEIVED_INVITES_FAILURE,
          reason: fetchRes.body.message,
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: GET_ALL_RECEIVED_INVITES_SUCCESS,
          invites: fetchRes.invites,
        });
      }
      Utils.hideLoading();
    },
    (fetchError) => {
      dispatch({
        type: GET_ALL_RECEIVED_INVITES_FAILURE,
        reason: fetchError.message,
      });
      logger.log('getAllReceivedInvites', fetchError);
    }
  );
};

export const acceptInvite = (code: string, isOnboarding?: boolean) => (
  dispatch: ThunkDispatch<{}, {}, ReceivedInvitesAction | OnboardingActionType>
) => {
  const conf = {
    path: `/api/Invites/${code}`,
    method: 'post',
  };

  Utils.showInfoLoadingWithText('Accepting invite');

  return adb2c.makeAuthenticatedApi(conf).then(
    (fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        Utils.showRequestFailedWithText('Failed to accept invite');
        throw fetchRes;
      } else {
        dispatch({
          type: isOnboarding ? ONBOARDING_INVITE_ACCEPTED : ACCEPT_INVITE_SUCCESS,
          codes: [code],
        });
      }
      Utils.hideLoading();
    },
    (fetchError) => {
      Utils.showRequestFailedWithText('Failed to accept invite');
      logger.log('acceptInvite', fetchError);
    }
  );
};

export const acceptAllInvites = (codes: string[], isOnboarding?: boolean) => (
  dispatch: ThunkDispatch<{}, {}, ReceivedInvitesAction | OnboardingActionType>
) => {
  Utils.showInfoLoadingWithText('Accepting all invites');

  const acceptPromises: Promise<string>[] = codes.map((code) => {
    const conf = {
      path: `/api/Invites/${code}`,
      method: 'post',
    };

    return new Promise((resolve, reject) => {
      adb2c.makeAuthenticatedApi(conf).then(
        (fetchRes: any) => {
          if (Utils.isError(fetchRes)) {
            reject(fetchRes);
          } else {
            resolve(code);
          }
        },
        (fetchError) => {
          reject(fetchError);
        }
      );
    });
  });

  const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
    input.status === 'rejected';

  const isFulfilled = (input: PromiseSettledResult<string>): input is PromiseFulfilledResult<string> =>
    input.status === 'fulfilled';

  return Promise.allSettled(acceptPromises)
    .then((results) => {
      const successCodes = results.filter(isFulfilled).map((result) => result.value);
      const failedCodes = results.filter(isRejected).map((result) => result.reason);
      if (failedCodes.length > 0 && successCodes.length === 0) {
        Utils.showRequestFailedWithText('Failed to accept all invites');
      } else if (failedCodes.length > 0) {
        Utils.showRequestFailedWithText('Failed to accept some invites');
      } else {
        Utils.hideLoading();
        dispatch({
          type: isOnboarding ? ONBOARDING_INVITE_ACCEPTED : ACCEPT_INVITE_SUCCESS,
          codes: successCodes,
        });
      }
    })
    .catch((err) => {
      Utils.showRequestFailedWithText('Failed to accept invites');
      logger.log('acceptAllInvites', err);
    });
};

export const goToBusiness = (businessId: string) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  Utils.showInfoLoadingWithText();
  resetVoicePlatform();

  APIUtils.getBusinesses().then((allUserBusinesses) => {
    dispatch(getBusinessesSuccess(allUserBusinesses));
    const currentBusiness = allUserBusinesses.find((business) => business.id === businessId);
    if (currentBusiness) {
      dispatch(
        setBusiness({
          id: currentBusiness.id,
          name: currentBusiness.name,
          isSelected: true,
          status: currentBusiness.status,
          logoUrl: currentBusiness.logoUrl,
          inviteDescription: currentBusiness.inviteDescription,
          notificationCount: currentBusiness.notificationCount,
        })
      );
    }
    dispatch(setBusinessAsync(businessId))
      .then(() => {
        Utils.hideLoading();
        dispatch(markAsCompleted());
        dispatch(push('/app/dashboard'));
      })
      .catch(() => {
        Utils.showRequestFailedWithText();
      });
  });
};
