import styled from 'styled-components';
import C from '../../shared/layouts/Container';
import { mediaFunc } from "../../styles/media";
import { palette } from '../../styles/variables';

const mediaMin = mediaFunc('min');
const mediaMax = mediaFunc('max');

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0px;

  ${mediaMax.lg`
    padding: 30px;
  `}
`;

export const Button = styled.button`
  color: ${palette.pink};
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
`

export const StepOuterContainer = styled(C)`
  padding-top: 20px;
  display: block;
`;

export const StepInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const StepContainer = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto;

  ${mediaMin.md`
    flex: 0 0 100%;
    max-width: 100%;
  `}

  ${mediaMin.lg`
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  `}

  ${mediaMin.xl`
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  `}
`;

export const StepContentContainer = styled.div`
  display: block;
`;