import Utils from '../../../lib/Utils';
import { CreateFaqUrlActionType, CREATE_FAQ_URL } from './create-faq-url.types';
import { DeleteFaqUrlActionType, DELETE_FAQ_URL } from './delete-faq-url.types';
import {
    UpdateFaqUrlAction,
    UPDATE_FAQ_URL
} from './update-faq-url.types';
import { FaqUrlsState, FaqUrl } from './manage-faq-urls.types';
import Logger from '../../../lib/Logger';
import {
    SUBMIT_FAQ_URLS_REQUEST,
    SUBMIT_FAQ_URLS_SUCCESS,
    SUBMIT_FAQ_URLS_FAILURE,
    submitFaqUrlsFailureAction
} from './submit-faq-urls.types';
import Fact from '../../../lib/Fact';

const logger = new Logger('manage-faq-urls.reducers');

export type ManageFaqUrlActionType = CreateFaqUrlActionType | DeleteFaqUrlActionType | UpdateFaqUrlAction;

const getNewFaqUrl = (): FaqUrl => ({
    id: Utils.uuidv4(),
    url: '',
});

const initialState: FaqUrlsState = {
    faqUrls: [
        getNewFaqUrl()
    ],
    isSubmittingFaqUrl: false,
    fact: undefined,
};

const createUpdateFaqUrlReducer = (state: FaqUrlsState = initialState, action: ManageFaqUrlActionType): FaqUrlsState => {
    let { faqUrls } = state;
    logger.log('createUpdateFaqUrlReducer::', 'state', state, 'action', action);
    switch (action.type) {
      case CREATE_FAQ_URL:
        const newFaqUrl = getNewFaqUrl();
        faqUrls.push(newFaqUrl);
        break;
      case DELETE_FAQ_URL:
        if (faqUrls.length > 1) {
          const deleteFaqUrlAction = action as DeleteFaqUrlActionType;
          faqUrls = faqUrls.filter((anFaqUrl) => anFaqUrl.id !== deleteFaqUrlAction.faqUrlId);
        }
        break;
      case UPDATE_FAQ_URL:
        const updateFaqUrlSuccessAction = action as UpdateFaqUrlAction;
        for (let i: number = 0; i < faqUrls.length; i++) {
          if (faqUrls[i].id === updateFaqUrlSuccessAction.faqUrlId) {
            faqUrls[i].url = updateFaqUrlSuccessAction.url;
            faqUrls[i] = {
              id: faqUrls[i].id,
              url: faqUrls[i].url
            }
            break;
          }
        }
        break;
    }

    return {
        ...state,
        faqUrls: [...faqUrls]
    };
}

const submitFaqUrlsReducer = (state: FaqUrlsState = initialState, action: ManageFaqUrlActionType): FaqUrlsState => {
    switch (action.type) {
        case SUBMIT_FAQ_URLS_REQUEST:
            return {
                ...state,
                isSubmittingFaqUrl: true,
            };
        case SUBMIT_FAQ_URLS_SUCCESS:
            return {
                ...state,
                fact: new Fact(SUBMIT_FAQ_URLS_SUCCESS),
                isSubmittingFaqUrl: false,
            };
        case SUBMIT_FAQ_URLS_FAILURE:
            return {
                ...state,
                isSubmittingFaqUrl: false,
                fact: new Fact(SUBMIT_FAQ_URLS_FAILURE, (action as submitFaqUrlsFailureAction).reason),
            };
        default:
            return state;
    }
};

const manageFaqUrlsReducer = (state: FaqUrlsState = initialState, action: ManageFaqUrlActionType): FaqUrlsState => {
    const manageFaqUrlsActionType: string[] = [
        CREATE_FAQ_URL,
        DELETE_FAQ_URL,
        UPDATE_FAQ_URL,
    ];
    if (manageFaqUrlsActionType.indexOf(action.type) !== -1) {
        return createUpdateFaqUrlReducer(state, action);
    }
    return submitFaqUrlsReducer(state, action);
};

export { manageFaqUrlsReducer };
