import {
    ManageEnterpriseQnaState,
    ManageEnterpriseQnasAction,
    CREATE_ENTERPRISE_QNA,
    EnterpriseQna,
    CreateEnterpriseQnaAction,
    UPDATE_ENTERPRISE_QNA,
    UpdateEnterpriseQnaAction,
    DELETE_ENTERPRISE_QNA,
    DeleteEnterpriseQnaAction,
    SUBMIT_ENTERPRISE_QNAS_REQUEST,
    SUBMIT_ENTERPRISE_QNAS_SUCCESS,
    SUBMIT_ENTERPRISE_QNAS_FAILURE,
    SumitEnterpriseQnasFailureAction,
    UPDATE_ENTERPRISE_QNAS,
    UpdateEnterpriseQnasAction,
} from "./manage-enterprise-qnas.types";
import Utils from "../../../lib/Utils";
import Fact from "../../../lib/Fact";
import Logger from "../../../lib/Logger";

const initialState: ManageEnterpriseQnaState = {
    enterpriseQnas: [],
    isSubmittingEnterpriseQnas: false,
    fact: undefined,
};

const logger = new Logger('manage-enterprise-qnas.reducers');

const mergeEnterpriseQnas = (someEnterpriseQnas: EnterpriseQna[], otherEnterpriseQnas: EnterpriseQna[]): EnterpriseQna[] => {
    logger.log('mergeEnterpriseQnas::', 'someEnterpriseQnas', someEnterpriseQnas, 'otherEnterpriseQnas', otherEnterpriseQnas);
    const allEnterpriseQnas: EnterpriseQna[] = [];
    const idToEnterpriseQnas: { [enterpriseQnaId: string]: EnterpriseQna } = {};
    for (const anEnterpriseQna of someEnterpriseQnas) {
        if (!(anEnterpriseQna.id in idToEnterpriseQnas)) {
            idToEnterpriseQnas[anEnterpriseQna.id] = anEnterpriseQna;
            allEnterpriseQnas.push(anEnterpriseQna);
        }
    }

    for (const anotherEnterpriseQna of otherEnterpriseQnas) {
        if (!(anotherEnterpriseQna.id in idToEnterpriseQnas)) {
            allEnterpriseQnas.push(anotherEnterpriseQna);
        }
    }

    return allEnterpriseQnas;
};

export const manageEnterpriseQnasReducer = (state: ManageEnterpriseQnaState = initialState, action: ManageEnterpriseQnasAction): ManageEnterpriseQnaState => {
    switch (action.type) {
        case CREATE_ENTERPRISE_QNA:
            const createEnterpriseQnaAction = action as CreateEnterpriseQnaAction;
            const enterpriseQna: EnterpriseQna = {
                id: Utils.uuidv4(),
                answer: createEnterpriseQnaAction.answer,
                question: createEnterpriseQnaAction.question,
                isIndustry: false,
            };
            return {
                ...state,
                enterpriseQnas: [...state.enterpriseQnas, enterpriseQna],
            };
        case UPDATE_ENTERPRISE_QNA:
            const updateEnterpriseQnaAction = action as UpdateEnterpriseQnaAction;
            for (let i = 0; i < state.enterpriseQnas.length; i++) {
                if (state.enterpriseQnas[i].id === updateEnterpriseQnaAction.id) {
                    state.enterpriseQnas[i].answer = updateEnterpriseQnaAction.answer;
                    state.enterpriseQnas[i].question = updateEnterpriseQnaAction.question;
                    break;
                }
            }
            return {
                ...state,
                enterpriseQnas: [...state.enterpriseQnas],
            };
        case UPDATE_ENTERPRISE_QNAS:
            return {
                ...state,
                enterpriseQnas: mergeEnterpriseQnas(state.enterpriseQnas, (action as UpdateEnterpriseQnasAction).enterpriseQnas),
            }
        case DELETE_ENTERPRISE_QNA:
            return {
                ...state,
                enterpriseQnas: state.enterpriseQnas.filter((anEnterpriseQna) => anEnterpriseQna.id !== (action as DeleteEnterpriseQnaAction).id),
            };
        case SUBMIT_ENTERPRISE_QNAS_REQUEST:
            return {
                ...state,
                isSubmittingEnterpriseQnas: true,
            };
        case SUBMIT_ENTERPRISE_QNAS_SUCCESS:
            return {
                ...state,
                isSubmittingEnterpriseQnas: false,
                fact: new Fact(SUBMIT_ENTERPRISE_QNAS_SUCCESS),
            };
        case SUBMIT_ENTERPRISE_QNAS_FAILURE:
            return {
                ...state,
                isSubmittingEnterpriseQnas: false,
                fact: new Fact(SUBMIT_ENTERPRISE_QNAS_FAILURE, (action as SumitEnterpriseQnasFailureAction).reason),
            };
        default:
            return state;
    }
};


