import styled from 'styled-components';
import media from '../../../../../styles/media';

export default styled.div`
  width: 100%;
  margin-bottom: 30px;

  ${media.xlg`
    margin-bottom: 20px;
  `}
`;
