import {
    UpdateFaqUrlAction,
    UPDATE_FAQ_URL,
} from './update-faq-url.types';


export const updateFaqUrl = (faqUrlId: string, url: string): UpdateFaqUrlAction => ({
    type: UPDATE_FAQ_URL,
    faqUrlId: faqUrlId,
    url: url
});
