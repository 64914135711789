import React from "react";
import styled from "styled-components";
import { palette } from "../../styles/variables";

const Container = styled.div`
  width: 231px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background: ${palette.mediumGray};
  border-radius: 24px;
`;

const PlanSection = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: rgb(87, 84, 82);
  width: 50%;
  position: relative;

  input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
    :checked + label {
      background-color: ${palette.white};
      color: ${palette.blue};
      box-shadow: rgba(15, 12, 9, 0.2) 0px 0px 4px 0px,
        rgba(15, 12, 9, 0.15) 0px 3px 6px 0px;
      border-radius: 24px;
    }
  }

  label {
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    transition: all 0.1s ease-in-out;
    :hover {
      cursor: pointer;
    }
  }
`;

type TipsProp = {
  pink?: boolean
}

const Tip = styled.div<TipsProp>`
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  background-color: ${palette.white};
  border-radius: 6px;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.pink ? palette.pink : palette.darkGray};
  width: 120px;
  white-space: normal;
  padding: 0.3625rem 0.5rem;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.10), 0 0 8px 0 rgba(0,0,0,0.10);
  z-index: 1;
`;

const Triangle = styled.div`
  position: absolute;
  left: 50%;
  width: 40px;
  height: 20px;
  transform: translateX(-50%);
  bottom: 100%;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 14px;
    left: 50%;
    width: 12px;
    height: 12px;
    content: "";
    background-color: ${palette.darkGray};
    transform: translateX(-50%) rotate(45deg);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px 0px,
      rgba(0, 0, 0, 0.2) 5px 5px 10px 0px;
  }
`;

type Props = {
  value: string;
  onChange: (frequency: string) => void;
};

export default ({ value, onChange }: Props) => {
  return (
    <Container>
      <PlanSection>
        <input
          type="radio"
          id="plan_toggler_left"
          name="planToggler"
          value="monthly"
          onChange={() => {
            onChange("monthly");
          }}
          checked={value === "monthly"}
        />
        <label htmlFor="plan_toggler_left">Monthly</label>
      </PlanSection>
      <PlanSection>
        <input
          type="radio"
          id="plan_toggler_right"
          name="planToggler"
          value="yearly"
          onChange={() => {
            onChange("yearly");
          }}
          checked={value === "yearly"}
        />
        <label htmlFor="plan_toggler_right">Annual</label>
        <Tip pink={value === "monthly"}>
          Select annual to save 30%!
          <Triangle />
        </Tip>
      </PlanSection>
    </Container>
  );
};
