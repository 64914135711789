import {
  CreateFaqUrlActionType,
  CREATE_FAQ_URL,
} from './create-faq-url.types';

const createFaqUrl = (): CreateFaqUrlActionType => ({
  type: CREATE_FAQ_URL,
});

export { createFaqUrl };
