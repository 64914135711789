import styled from 'styled-components';

import { palette } from '../../../styles/variables';

export default styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${palette.lightBlack};
  margin-top: 15px;
  font-family: Hind, sans-serif;
`;
