import React, { FC, useEffect } from 'react';
import { useInterval } from './hooks/useInterval';
import semver from 'semver';
import APIUtils from './lib/APIUtils';
import Utils from './lib/Utils';

type AutoUpdateProps = {
  children: React.ReactNode;
};

// Poll for updates every hour
const AUTO_UPDATE_INTERVAL = 60 * 60 * 1000;

type VersionInfo = {
  Version: string;
  ArtifactVersion: string;
  IsRelease: boolean;
  TargetVersion: string;
  Year: number;
  WeekNumber: number;
  Release: number;
  BuildId: string;
  CommitSha: string;
};

const AutoUpdate: FC<AutoUpdateProps> = ({ children }) => {
  const [versionInfo, setVersionInfo] = React.useState<VersionInfo>();
  const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

  useEffect(() => {
    APIUtils.getVersionInfo().then((newVersionInfo) => {
      setVersionInfo(newVersionInfo);
    });
  }, []);

  useInterval(async () => {
    try {
      const newVersionInfo = await APIUtils.getVersionInfo();
      if (versionInfo && newVersionInfo) {
        if (semver.gt(newVersionInfo.TargetVersion, versionInfo.TargetVersion) && !showConfirm) {
          setShowConfirm(true);
          Utils.showConfirmPopup({
            type: 'info',
            title: 'New Version Available',
            text: 'A new version of Zammo is available. Please refresh your browser to get the latest version.',
            showCancelButton: false,
            confirmButtonText: 'Refresh',
            allowOutsideClick: false,
          }).then((confirm) => {
            if (confirm.value) {
              window.location.reload();
            } else {
              setShowConfirm(false);
            }
          });
        }
      }
      setVersionInfo(newVersionInfo);
    } catch {}
  }, AUTO_UPDATE_INTERVAL);

  return <>{children}</>;
};

export default AutoUpdate;
