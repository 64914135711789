
import styled from "styled-components";
import media from "../../../../../styles/media";

export default styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  ${media.xlg`
    margin-bottom: 20px;
  `}
  > div {
    margin-bottom: 0;
  }

  ${media.md`
    display: block;
  `}

  > button {
    line-height: 46px;
    padding: 0 20px;
    margin-left: 20px;
    white-space: nowrap;
    display: block;
    ${media.xlg`
      line-height: 40px;
    `}
    ${media.md`
      margin: 20px 0 0 auto;
    `}
  }
`
