import React, { useEffect }  from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { Icon } from '@iconify/react';
import arrowLeft from '@iconify/icons-simple-line-icons/arrow-left';
import Breadcrumb from "../../shared/breadcrumb";
import { palette } from "../../styles/variables";
import C from "../../shared/layouts/Container";
import PageTitle from "../../shared/PageTitle";
import { AppState } from "../../store";
import { loadBusinessInvoices } from "../../store/billing/billing.actions";
import { Invoice } from "../../store/billing/billing.types";
const Container = styled(C)`
  display: block;
`

const Invoices = styled.div`
  height: 400px;
  overflow: auto;
`

const BackLink = styled(Link)`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
`

const Downloadlink = styled.a`
  display: block;
  margin: 10px 0;
  color: ${palette.blue};
`

export default () => {
  const dispatch = useDispatch()
  const invoices = useSelector<AppState, Invoice[]>(state => state.billing.invoices);
  const businessId = useSelector<AppState, string>(state => state.businessReducer.business.id as string);
  useEffect(() => {
    if (businessId) {
      dispatch(loadBusinessInvoices(businessId))
    }
  }, [dispatch, businessId])
  return (
    <>
      <Breadcrumb path="Subscription Settings" />
      <Container>
        <BackLink to="/app/subscriptionsettings"><Icon icon={arrowLeft} /> Back</BackLink>
        <PageTitle title="Your Invoices" />
        <Invoices>
          {invoices && invoices.length > 0 && invoices.map(invoice => (
            <Downloadlink key={invoice.id} href={invoice.downloadUrl}>{invoice.name}</Downloadlink>
          ))}
        </Invoices>
      </Container>
    </>
  );
}
