import {
    GetEnterpriseIndustryQnasState,
    GetEnterpriseIndustryQnasAction,
    GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST,
    GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE,
    GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS,
    GetEnterpriseIndustryQnasSuccessAction,
    GetEnterpriseIndustryQnasFailureAction,
} from "./get-enterprise-industry-qnas.types";
import Fact from "../../../lib/Fact";

const initialState: GetEnterpriseIndustryQnasState = {
    enterpriseQnas: [],
    fact: undefined,
    isGettingEnterpriseIndustryQnas: false,
};

const getEnterpriseIndustryQnasReducer = (state: GetEnterpriseIndustryQnasState = initialState, action: GetEnterpriseIndustryQnasAction): GetEnterpriseIndustryQnasState => {
    switch (action.type) {
        case GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST:
            return {
                ...state,
                isGettingEnterpriseIndustryQnas: true,
            };
        case GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE:
            return {
                ...state,
                isGettingEnterpriseIndustryQnas: false,
                fact: new Fact(GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE, (action as GetEnterpriseIndustryQnasFailureAction).reason),
            };
        case GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS:
            return {
                ...state,
                isGettingEnterpriseIndustryQnas: false,
                fact: new Fact(GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS),
                enterpriseQnas: (action as GetEnterpriseIndustryQnasSuccessAction).enterpriseQnas,
            };
        default:
            return state;
    }
}

export { getEnterpriseIndustryQnasReducer };
