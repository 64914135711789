import React, { FC, useState } from 'react';
import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { SearchKnowledgeBaseWebsiteDataSourceConfigurationData } from '../../../store/search-knowledge-base/search-knowledge-base-data-source.types';
import { TextField } from 'office-ui-fabric-react';

type SearchKnowledgeBaseWebsiteEditPatternModalProps = {
  editingPattern: SearchKnowledgeBaseWebsiteDataSourceConfigurationData;
  onOk: (pattern: SearchKnowledgeBaseWebsiteDataSourceConfigurationData) => void;
  onDismiss: () => void;
};

const SearchKnowledgeBaseWebsiteEditPatternModal: FC<SearchKnowledgeBaseWebsiteEditPatternModalProps> = ({
  editingPattern,
  onOk,
  onDismiss,
}) => {
  const [patternValue, setPatternValue] = useState<string>(editingPattern.value);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleSave = () => {
    if (!patternValue) {
      setError('Pattern cannot be empty');
      return;
    }
    onOk({ ...editingPattern, value: patternValue });
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Edit Pattern',
      }}
      minWidth={400}
      onDismiss={onDismiss}
    >
      <TextField
        label="Pattern"
        value={patternValue}
        errorMessage={error}
        onChange={(e, newValue) => {
          setError(undefined);
          setPatternValue(newValue || '');
        }}
      />
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text="Cancel" />
        <PrimaryButton onClick={() => handleSave()} text="Save" />
      </DialogFooter>
    </Dialog>
  );
};

export default SearchKnowledgeBaseWebsiteEditPatternModal;
