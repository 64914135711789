import React from "react";
import styled from "styled-components";
import { palette } from "../../styles/variables";

interface Props {
  text: string;
}

const Container = styled.div`
  height: 30px;
  min-width: 250px;
  width: 100%;
  position: relative;
`

const Line = styled.div`
  position: absolute;
  left: 0;
  top: calc(50% - 1px);
  height: 1px;
  display: block;
  width: 100%;
  background: ${palette.gray};
`

const Text = styled.div`
  padding: 0 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${palette.white};
  font-weight: 500;
  position: absolute;
`

export default ({ text }: Props) => {
  return (
    <Container>
      <Line />
      <Text>{text}</Text>
    </Container>
  )
}
