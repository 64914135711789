import styled from "styled-components";
import media from "../../styles/media";

export const Container = styled.div`
  position: fixed;
  top: 95px;
  right: 30px;
  z-index: 1;
  ${media.xlg`
    top: 88px;
  `}
  ${media.md`
    top: 73px;
  `}
`;

export const CloseIcon = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: -20px;
  right: -20px;
`;
