import styled, { css } from "styled-components"
import { palette } from "../../styles/variables";
import media from "../../styles/media";

export const inputStyles = css`
  font-size: 20px;
  font-weight:400;
  color: ${palette.black};
  line-height: 1.5;
  padding: 8px 20px;
  background: ${palette.lightGray};
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 5px;
  ${media.xlg`
    font-size: 16px;
  `}
`

export default styled.input`
  ${inputStyles};
`
