import { IStackStyles } from 'office-ui-fabric-react/lib/Stack';
import { palette } from '../../styles/variables';

export const containerStyles: IStackStyles = {
  root: {
    margin: '54px',
    maxHeight: 'calc(100vh - 230px)',
    position: 'relative',
    overflowY: 'auto',
  },
};

export const headerStyles: IStackStyles = {
  root: {
    paddingBottom: 10,
    position: 'sticky',
    top: 0,
    background: palette.white,
    zIndex: 1,
  },
};

export const skeletonNotificationContainerStyles: IStackStyles = {
  root: {
    padding: '10px 20px',
    zIndex: 0,
  },
};

export const notificationContainerStyles: IStackStyles = {
  root: {
    padding: '10px 20px',
    '&:hover': {
      background: palette.lightGray,
    },
  },
};

export const timeAgoStyles: IStackStyles = { root: { display: 'inline', color: palette.gray1 } };

export const paginationStyles: IStackStyles = { root: { position: 'sticky', bottom: 0, background: palette.white } };
