export const SET_VOICE_AND_CHAT = "SET_VOICE_AND_CHAT";

export type VoiceAndChat = {
  isVoice: boolean;
};

export type SetVoiceAndChatAction = {
  type: typeof SET_VOICE_AND_CHAT;
  voiceAndChat: VoiceAndChat;
};

export type VoiceAndChatActions =
  | SetVoiceAndChatAction;

export type VoiceAndChatState = {
  voiceAndChat: VoiceAndChat;
};
