import {
  ReceivedInvitesState,
  ReceivedInvitesAction,
  GetAllReceivedInvitesSuccessAction,
  GET_ALL_RECEIVED_INVITES_SUCCESS,
  GET_ALL_RECEIVED_INVITES_REQUEST,
  GET_ALL_RECEIVED_INVITES_FAILURE,
  ACCEPT_INVITE_SUCCESS,
  GetAllReceivedInvitesFailedAction,
  AcceptInviteSuccessAction,
} from './received-invites.types';
import Fact from '../../lib/Fact';

const initialState: ReceivedInvitesState = {
  invites: [],
  isLoadingInvites: false,
};

const receivedInvitesReducer = (
  state: ReceivedInvitesState = initialState,
  action: ReceivedInvitesAction
): ReceivedInvitesState => {
  switch (action.type) {
    case GET_ALL_RECEIVED_INVITES_REQUEST: {
      return {
        ...state,
        isLoadingInvites: true,
      };
    }

    case GET_ALL_RECEIVED_INVITES_SUCCESS: {
      const successAction = action as GetAllReceivedInvitesSuccessAction;
      return {
        ...state,
        invites: successAction.invites.filter((invite) => invite.isActive),
        isLoadingInvites: false,
        fact: new Fact(GET_ALL_RECEIVED_INVITES_SUCCESS),
      };
    }

    case GET_ALL_RECEIVED_INVITES_FAILURE:
      return {
        ...state,
        isLoadingInvites: false,
        fact: new Fact(GET_ALL_RECEIVED_INVITES_FAILURE, (action as GetAllReceivedInvitesFailedAction).reason),
      };

    case ACCEPT_INVITE_SUCCESS:
      const codes = (action as AcceptInviteSuccessAction).codes;
      return {
        ...state,
        invites: state.invites.map((invite) => {
          if (codes.includes(invite.code)) {
            return {
              ...invite,
              isAccepted: true,
            };
          }
          return invite;
        }),
        fact: new Fact(ACCEPT_INVITE_SUCCESS),
      };

    default:
      return state;
  }
};

export default receivedInvitesReducer;
