import { connect } from 'react-redux';
import pathOr from 'ramda/src/pathOr';
import DescriptionInput from './DescriptionInput';

import { AppState } from '../../../store';

import { updateShortDescription } from '../../../store/business/business.actions';

const mapStateToProps = (state: AppState) => ({
  description: pathOr('', ['businessReducer', 'business', 'shortDescription'], state) as string,
  label: 'Short Description',
  textareaPlaceholder: 'Our voice app lets you learn more about our organization.',
  popoverText: 'This is a short description of what your voice app offers to your users.  It is seen both by the voice platforms and your users. We have prepopulated this field and we suggest you keep it this way without changing anything.',
  textareaCharacterLimit: 100,
});

const mapDispatchToProps = {
  updateTextArea: updateShortDescription,
};

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionInput);
