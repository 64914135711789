import styled from 'styled-components';
import media from '../../../../../styles/media';
import { palette } from '../../../../../styles/variables';

export default styled.button`
  border: none;
  background: ${palette.mediumBlue};
  border-radius: 2em;
  line-height: 2em;
  color: white;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  padding: 0 3em;
  margin-left: auto;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  ${media.lg`
    font-size: 18px;
    padding: 0px 30px;
  `}
`;
