import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pathOr from 'ramda/src/pathOr';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import desktopIcon from '@iconify/icons-simple-line-icons/screen-desktop';
import questionIcon from '@iconify/icons-simple-line-icons/question';
import microphoneIcon from '@iconify/icons-simple-line-icons/microphone';

import { palette } from '../../styles/variables';
import hero from '../../assets/images/404.png';
import DividerWithText from '../../shared/DividerWithText';
import QuickLink from './QuickLink';
import Footer from '../../shared/layouts/Footer';
import Navbar from './Navbar';
import media from '../../styles/media';
import { AppState } from '../../store';
import { FeatureFlag } from '../../store/feature-flags/feature-flags.types';
import { loadFeatureFlags } from '../../store/feature-flags/feature-flags.actions';

const Body = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 9rem 1rem 3.5rem;
  ${media.xl`
    padding-top: 6rem;
  `}
`;

const Container = styled.div`
  text-align: center;
  font-weight: 500;
  flex: 1;
`;

const Img = styled.img`
  max-width: 800px;
  width: 100%;
`;

const H1 = styled.h1`
  text-transform: uppercase;
  color: ${palette.blue};
  font-size: 1.875rem;
  font-weight: 600;
  margin: 0.25rem 0 0.75rem;
`;

const DividerContainer = styled.div`
  max-width: 850px;
  width: 100%;
  margin: 3.5rem auto;
  padding: 0 1.25rem;
`;

const Links = styled.div`
  text-align: center;
  margin: auto;
  max-width: 980px;
  width: 100%;
  > a {
    display: inline-block;
    margin: 0.5rem;
    width: 300px;
    vertical-align: top;
  }
`;

const FooterDesc = styled.div`
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  max-width: 800px;
  width: 100%;
  margin: 1rem auto;
`;

export default () => {
  const dispatch = useDispatch();
  const { business } = useSelector((state: AppState) => ({
    business: state.businessReducer.business,
    userProfile: state.account.profile,
    featureFlags: pathOr([], ['featureFlags', 'featureFlags'], state) as FeatureFlag[],
  }));

  useEffect(() => {
    if (business && business.id) {
      dispatch(loadFeatureFlags(business.id));
    }
  }, [dispatch, business]);

  return (
    <>
      <Navbar />
      <Body>
        <Container>
          <Img src={hero} alt="Zammo 404" />
          <H1>Oops! This Page doesn't exist</H1>
          <div>Don't worry - thisis not a dead end. Just scroll down to see more options.</div>
          <DividerContainer>
            <DividerWithText text="One of these links might be helpful..." />
          </DividerContainer>
          <Links>
            <QuickLink icon={<Icon icon={desktopIcon} />} title="Zammo Dashboard" to="/app/dashboard" />
            <QuickLink icon={<Icon icon={questionIcon} />} title="QnA Content" to="/app/qna" />
            <QuickLink icon={<Icon icon={microphoneIcon} />} title="Voice Platforms" to="/app/channels" />
          </Links>
        </Container>
        <FooterDesc>
          One more thing before you go! Don’t forget to utilize the little blue widget in the right-hand corner to
          search our FAQs or reach our support team. Help is not far away.
        </FooterDesc>
      </Body>
      <Footer />
    </>
  );
};
