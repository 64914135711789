import ADB2C from '../../lib/ADB2C'

export const LOGIN_REQUEST: string = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE: string = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST: string = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS: string = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE: string = 'LOGOUT_FAILURE';

export type LoginRequestAction = {
    type: typeof LOGIN_REQUEST;
};

export type LoginFailureAction = {
    type: typeof LOGIN_FAILURE;
};

export type LoginSuccessAction = {
    type: typeof LOGIN_SUCCESS;
    adB2c: ADB2C;
};

export type LogoutRequestAction = {
    type: typeof LOGOUT_REQUEST;
};

export type LogoutSuccessAction = {
    type: typeof LOGOUT_SUCCESS;
};

export type LogoutFailureAction = {
    type: typeof LOGOUT_FAILURE;
};

export type UserState = {
    adb2c: ADB2C;
}

export type UserActionType = LoginRequestAction | LoginFailureAction | LoginSuccessAction | LogoutRequestAction | LogoutFailureAction;
