import React from 'react';
import { useSelector } from "react-redux";
import queryString from 'query-string';

import { AppState } from "../../store";
import ConfirmModal from './ConfirmModal';

interface Props {
  location: any;
  verifyEmail: (emailVerificationId: string) => Promise<void>;
}

export default () => {
  const search = useSelector((state: AppState) => state.router.location.search) as string;
  const queryParameters = queryString.parse(search);
  const brandVerificationConfirmationId  = queryParameters.brandVerificationConfirmationId as string;
  return (
    <ConfirmModal
      brandVerificationConfirmationId={brandVerificationConfirmationId}
      show={true}
      onClose={() => null}
    />
  );
}
