import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.svg'
import media from '../../styles/media';

const Container = styled.div`
  ${media.xl`
    position: relative;    
  `}
`;

const Figure = styled.figure`
  width: 86px;
  height: 72px;
  margin: 0;
  border-right: 1px solid rgba(255,255,255,.1);
  text-align: center;
  line-height: 72px;
  display: inline-block;
  vertical-align: middle;

  ${media.xlg`
    width: 80px;
    height: 65px;
    line-height: 65px;
  `}
  ${media.md`
    width: 60px;
    height: 50px;
    line-height: 50px;
    img {
      max-width: 50%;
    }
  `}
  ${media.sm`
    width: 50px;
  `}
`;

export default () => (
  <Container>
    <a href="#/" onClick={ (e) => e.preventDefault() }>
      <Figure><img src={Logo} alt="Zammo Logo" /></Figure>
    </a>
  </Container>
);
