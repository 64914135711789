import { connect } from "react-redux";
import pathOr from 'ramda/src/pathOr';
import LinkInput from './LinkInput';
import { AppState } from '../../../store';

import { updatePrivacyPolicyUrl } from '../../../store/business/business.actions';

const mapStateToProps = (state: AppState) => ({
  label: 'Enter your organization\'s Privacy Policy link from your website',
  popoverContentParagraph: 'Please enter your website’s privacy policy link (typically found at the bottom of your website). If you have multiple privacy policy pages, just select the single link you want to place here. If you do not have a privacy policy, you will need to write a privacy policy, create the webpage and place the link here.',
  termsOrPrivacy: 'privacy',
  url: pathOr('', ['businessReducer', 'business', 'privacyUrl'], state) as string,
});

const mapDispatchToProps = {
  updateUrl: updatePrivacyPolicyUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkInput);
