import React from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";

import DropzoneUploadContentDiv from "./DropzoneUploadContentDiv";
import DropzonePreview from "./DropzonPreview";
import { palette } from "../../styles/variables";

const Section = styled.section`
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: block;
`;

const DropzoneWrapper = styled.div`
  &:hover {
    background-size: 30px 30px;
    background-image: linear-gradient(
      -45deg,
      white 25%,
      transparent 25%,
      transparent 50%,
      white 50%,
      white 75%,
      transparent 75%,
      transparent
    );
    animation: stripes 2s linear infinite;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: ${palette.darkGray};
  background-color: white;
  background-image: none;
  text-align: center;
  border: 2px solid ${palette.lightGray};
  transition: border-color 0.15s linear;
  border: none;
  background: ${palette.lightGray};
  min-height: 165px;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial;
`;

type Props = {
  imageFile?: File;
  imageUrl?: string;
  onFilesDrop: (files: File[]) => void;
  onRemove: () => void;
};

export default ({ imageFile, imageUrl, onFilesDrop, onRemove }: Props) => (
  <Dropzone
    accept="image/jpeg, image/png"
    multiple={false}
    onDrop={onFilesDrop}
    noDrag={!!imageFile || !!imageUrl}
  >
    {({ getRootProps, getInputProps }) => (
      <Section>
        <div {...getRootProps({ className: "dropzone" })}>
          <DropzoneWrapper>
            {!!imageFile || !!imageUrl ? (
              <DropzonePreview
                imageFile={imageFile as File}
                imageUrl={imageUrl as string}
                onRemove={onRemove}
              />
            ) : (
              <>
                <input {...getInputProps()} />
                <DropzoneUploadContentDiv />
              </>
            )}
          </DropzoneWrapper>
        </div>
      </Section>
    )}
  </Dropzone>
);
