export const TRACKING_ORGANIZATION_PROFILE_REQUEST = 'TRACKING_ORGANIZATION_PROFILE_REQUEST';
export const TRACKING_ORGANIZATION_PROFILE_SUCCESS = 'TRACKING_ORGANIZATION_PROFILE_SUCCESS';
export const TRACKING_ORGANIZATION_PROFILE_FAILURE = 'TRACKING_ORGANIZATION_PROFILE_FAILURE';

export const QNAS_COUNT_REQUEST = 'QNAS_COUNT_REQUEST';
export const QNAS_COUNT_SUCCESS = 'QNAS_COUNT_SUCCESS';
export const QNAS_COUNT_FAILURE = 'QNAS_COUNT_FAILURE';

export const TRACKING_VOICE_PLATFORM_SETUP_REQUEST = 'TRACKING_VOICE_PLATFORM_SETUP_REQUEST';
export const TRACKING_VOICE_PLATFORM_SETUP_SUCCESS = 'TRACKING_VOICE_PLATFORM_SETUP_SUCCESS';
export const TRACKING_VOICE_PLATFORM_SETUP_FAILURE = 'TRACKING_VOICE_PLATFORM_SETUP_FAILURE';

export const OVERALL_STATUS_REQUEST = 'OVERALL_STATUS_REQUEST';
export const OVERALL_STATUS_SUCCESS = 'OVERALL_STATUS_SUCCESS';
export const OVERALL_STATUS_FAILURE = 'OVERALL_STATUS_FAILURE';


export type RequestFailureAction = {
  type: string
}

export type TrackingOrganizationProfileSuccessAction = {
  type: typeof TRACKING_ORGANIZATION_PROFILE_SUCCESS,
  payload: {
    steps: number,
    completed: number,
  }
}

export type TrackingVoicePlatformSetupSuccessAction = {
  type: typeof TRACKING_VOICE_PLATFORM_SETUP_SUCCESS,
  payload: {
    steps: number,
    completed: number,
  }
}

export type QnaCountsAction = {
  type: typeof QNAS_COUNT_SUCCESS,
  payload: {
    draft: number,
    published: number,
  }
}

export type OverallStatusSuccessAction = {
  type: typeof OVERALL_STATUS_SUCCESS,
  payload: {
    score: number,
    addTeamMembers: string,
    organisationProfile: string,
    publishedQna: string,
    userProfile: string,
    voicePlatformSetup: string,
  }
}

export type DashboardActions = RequestFailureAction |
TrackingOrganizationProfileSuccessAction |
TrackingVoicePlatformSetupSuccessAction |
QnaCountsAction |
OverallStatusSuccessAction;

export type DashboardState = {
  organization: {
    steps: number,
    completed: number,
  }
  qna: {
    draft: number,
    published: number,
  },
  teamMembers: {
    steps: number,
    completed: number,
  },
  voicePlatform: {
    steps: number,
    completed: number,
  },
  overall: {
    score: number,
    addTeamMembers: string,
    organisationProfile: string,
    publishedQna: string,
    userProfile: string,
    voicePlatformSetup: string,
    isLoaded: boolean
  }
}
