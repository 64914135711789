import UserInvite from './UserInvite';

export enum UserRole {
  Guest = 10,
  Legal = 11,
  Contributor = 20,
  Publisher = 30,
  Admin = 40,
  Owner = 50,
}

export type User = {
  id: string;
  photoUrl: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  emailIsVerified: boolean;
  activeBusiness?: string;
  isZammo: boolean;
  jobTitle: string;
  receivedInvites?: UserInvite[];
};
