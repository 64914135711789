import Fact from '../../lib/Fact';
import UserInvite from '../../models/UserInvite';

export const GET_ALL_RECEIVED_INVITES_REQUEST = 'GET_ALL_RECEIVED_INVITES_REQUEST';
export const GET_ALL_RECEIVED_INVITES_SUCCESS = 'GET_ALL_RECEIVED_INVITES_SUCCESS';
export const GET_ALL_RECEIVED_INVITES_FAILURE = 'GET_ALL_RECEIVED_INVITES_FAILURE';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';

export type GetAllReceivedInvitesRequestAction = {
  type: typeof GET_ALL_RECEIVED_INVITES_REQUEST;
};

export type GetAllReceivedInvitesFailedAction = {
  type: typeof GET_ALL_RECEIVED_INVITES_FAILURE;
  reason: string;
};

export type GetAllReceivedInvitesSuccessAction = {
  type: typeof GET_ALL_RECEIVED_INVITES_SUCCESS;
  invites: UserInvite[];
};

export type AcceptInviteSuccessAction = {
  type: typeof ACCEPT_INVITE_SUCCESS;
  codes: string[];
};

export type ReceivedInvitesState = {
  invites: (UserInvite & { isAccepted?: boolean })[];
  fact?: Fact;
  isLoadingInvites: boolean;
};

export type ReceivedInvitesAction =
  | GetAllReceivedInvitesRequestAction
  | GetAllReceivedInvitesSuccessAction
  | GetAllReceivedInvitesFailedAction
  | AcceptInviteSuccessAction;
