import React from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-simple-line-icons/arrow-right';
import arrowLeft from '@iconify/icons-simple-line-icons/arrow-left';
import { ThunkDispatch } from 'redux-thunk';

import { connect } from 'react-redux';

import ADB2C from '../../../lib/ADB2C';
import FormButton from '../shared/FormButton';
import FormBackButton from '../shared/FormBackButton';

import Fact from '../../../lib/Fact';
import Utils from '../../../lib/Utils';

import { AppState } from '../../../store';
import { FaqUrl } from '../../../store/onboarding/faq-urls/manage-faq-urls.types';
import { ManageFaqUrlActionType } from '../../../store/onboarding/faq-urls/manage-faq-urls.reducers';
import { SUBMIT_FAQ_URLS_SUCCESS, SUBMIT_FAQ_URLS_FAILURE } from '../../../store/onboarding/faq-urls/submit-faq-urls.types';

import { moveStep, nextStep } from '../../../store/onboarding/onboarding.actions';
import { submitFaqUrls } from '../../../store/onboarding/faq-urls/submit-faq-urls.actions';
import Logger from '../../../lib/Logger';
import { StepMovement } from '../../../store/onboarding/onboarding.types';
import { Business } from '../../../store/business/business.types';
import media from '../../../styles/media';


const logger = new Logger('supplied-faq-urls.Buttons');

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.sm`
    flex-direction: column-reverse;
  `}
`;

interface Props {
  adb2c: ADB2C;
  fact: Fact | undefined,
  faqUrls: FaqUrl[];
  isSubmittingFaqUrl: boolean,
  business: Business,

  nextStep: typeof nextStep;
  moveStep: typeof moveStep;
  submitFaqUrls: typeof submitFaqUrls;
  dispatch: ThunkDispatch<{}, {}, ManageFaqUrlActionType>;
}

const mapStateToProps = (state: AppState) => ({
  adb2c: state.userReducer.adb2c as ADB2C,
  faqUrls: state.manageFaqUrlsReducer.faqUrls,
  fact: state.manageFaqUrlsReducer.fact,
  isSubmittingFaqUrl: state.manageFaqUrlsReducer.isSubmittingFaqUrl,
  business: state.businessReducer.business as Business,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, ManageFaqUrlActionType>) => ({
  nextStep,
  moveStep,
  submitFaqUrls,
  dispatch,
});

class Component extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    const {
      dispatch, fact, isSubmittingFaqUrl, nextStep,
    } = this.props;

    logger.log('componentDidUpdate::', 'prev props', prevProps, 'current props', this.props);
    if (isSubmittingFaqUrl) {
      Utils.showInfoLoadingWithText('submitting the FAQs');
    } else if (Utils.isFact(fact, prevProps.fact, SUBMIT_FAQ_URLS_SUCCESS)) {
      dispatch(nextStep());
    } else if (Utils.isFact(fact, prevProps.fact, SUBMIT_FAQ_URLS_FAILURE)) {
      Utils.hideLoading();
      Swal.fire({
        title: 'Ooops..',
        type: 'error',
        html: `<p>An error occurred while we were trying to submit your FAQ Urls:<br /> ${(fact as Fact).message}.</p>`,
      });
    } else if (Swal.isVisible()) {
      Utils.hideLoading();
    }
  }

  onBackClick = (event: React.MouseEvent) => {
    const { adb2c, dispatch, moveStep } = this.props;

    logger.log('onBackClick::');
    event.preventDefault();
    Utils.showInfoLoadingWithText('');
    dispatch(moveStep(adb2c, StepMovement.PREVIOUS));
  }

  onSubmitClick = (event: React.MouseEvent) => {
    const {
      dispatch, faqUrls, submitFaqUrls, business,
    } = this.props;
    logger.log('onSubmitClick::');
    event.preventDefault();
    const nullUrls = faqUrls.filter((url) => !Utils.isValidString(url.url));
    if (nullUrls.length > 0) {
      Utils.showRequestFailedWithText('All the urls must be valid');
      return;
    }
    dispatch(submitFaqUrls(faqUrls, business.id));
  }

  render() {
    return (
      <Container>
        <FormBackButton onClick={this.onBackClick}>
          <Icon icon={arrowLeft} />
          {' '}
Back
        </FormBackButton>
        <FormButton onClick={this.onSubmitClick}>
Add FAQs &amp; Continue
          <Icon icon={arrowRight} />
        </FormButton>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
