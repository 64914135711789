import styled from 'styled-components';

import { palette } from '../../../styles/variables';

export default styled.p`
  font-size: 14px;
  color: ${palette.darkGray};
  margin-top: 15px;
  font-family: Montserrat,sans-serif;
  padding: 0;
  border: none;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: -.2px;
  line-height: 24px;
`;
