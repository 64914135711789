export const SUBMIT_FAQ_URLS_REQUEST: string = 'SUBMIT_FAQ_URLS_REQUEST';
export const SUBMIT_FAQ_URLS_SUCCESS: string = 'SUBMIT_FAQ_URLS_SUCCESS';
export const SUBMIT_FAQ_URLS_FAILURE: string = 'SUBMIT_FAQ_URLS_FAILURE';

export type SubmitFaqUrlsRequestAction = {
    type: typeof SUBMIT_FAQ_URLS_REQUEST;
};

export type SubmitFaqUrlsSuccessAction = {
    type: typeof SUBMIT_FAQ_URLS_SUCCESS;
};

export type submitFaqUrlsFailureAction = {
    type: typeof SUBMIT_FAQ_URLS_FAILURE;
    reason: string;
};

export type SubmitFaqUrlsAction = SubmitFaqUrlsRequestAction | SubmitFaqUrlsSuccessAction | submitFaqUrlsFailureAction;
