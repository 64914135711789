import React, { useEffect, useMemo, useState } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { SearchKnowledgeBaseRawDocumentsDataSourceDtoEntry } from '../../store/search-knowledge-base/search-knowledge-base-data-source.types';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Stack, StackItem } from 'office-ui-fabric-react/lib/Stack';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { debounce } from 'ts-debounce';
import { Pagination } from '@bfc/zammo/lib/ui-components/Pagination';

type DataSourceManagementViewRawDocumentsModalProps = {
  indexedFiles: SearchKnowledgeBaseRawDocumentsDataSourceDtoEntry[];
  onDeleteFile: (fileId: string) => void;
};

const DataSourceManagementViewRawDocumentsModal = ({
  indexedFiles,
  onDeleteFile,
}: DataSourceManagementViewRawDocumentsModalProps) => {
  const PAGE_SIZE = 10;

  const deleteUrlButtonLink = (item: SearchKnowledgeBaseRawDocumentsDataSourceDtoEntry) => {
    return <IconButton title="Delete" iconProps={{ iconName: 'Delete' }} onClick={() => onDeleteFile(item.id)} />;
  };

  const columns: IColumn[] = [
    {
      key: 'fileName',
      name: 'File Name',
      fieldName: 'name',
      minWidth: 210,
      isMultiline: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
    },
    {
      key: 'actions',
      name: 'Remove from index',
      minWidth: 30,
      isSorted: false,
      isSortedDescending: false,
      onRender: deleteUrlButtonLink,
      data: 'string',
    },
  ];

  const [pageIndex, setPageIndex] = useState(1);
  const [filteredWebsites, setFilteredWebsites] = useState<SearchKnowledgeBaseRawDocumentsDataSourceDtoEntry[]>(
    indexedFiles
  );

  const pageCount = Math.ceil(filteredWebsites.length / PAGE_SIZE);

  const displayedItems = filteredWebsites.slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE);

  const onChangeText = (text: string | undefined): void => {
    setFilteredWebsites(
      text ? indexedFiles.filter((f) => f.name.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1) : indexedFiles
    );
  };

  const debouncedResults = useMemo(() => {
    return debounce(onChangeText, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  return (
    <Stack verticalAlign="center" tokens={{ maxHeight: '70vh', childrenGap: 8 }}>
      <StackItem>
        <SearchBox placeholder="Filter Files" onChange={(ev, text) => debouncedResults(text)} />
      </StackItem>

      <Stack horizontalAlign="center">
        <StackItem grow={1} styles={{ root: { overflow: 'auto', width: '100%' } }}>
          <DetailsList
            items={displayedItems}
            compact={false}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={(item: SearchKnowledgeBaseRawDocumentsDataSourceDtoEntry) => item.id}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={false}
          />
        </StackItem>
        {pageCount > 1 && (
          <Stack horizontalAlign="center">
            <Pagination pageCount={pageCount} onChange={setPageIndex} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DataSourceManagementViewRawDocumentsModal;
