import React from 'react';

import styled from 'styled-components';

import FaqUrls from './FaqUrls';
import Label from './Label';

import Buttons from './Buttons';
import BottomContent from './BottomContent';

import media from '../../../styles/media';

const RowContainer = styled.div`
  margin-bottom: 30px;
  ${media.xlg`
    margin-bottom: 20px;
  `}
`;

export default () => (
  <RowContainer>
    <Label />
    <FaqUrls />
    <Buttons />
    <BottomContent />
  </RowContainer>
);
