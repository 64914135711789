import React from "react"
import styled from "styled-components"
import PopperJS from 'popper.js';
import TooltipTrigger from 'react-popper-tooltip';
import { Trigger } from 'react-popper-tooltip/dist/types';
import 'react-popper-tooltip/dist/styles.css';
import { palette } from '../../styles/variables';
import QuestionTrigger from "./QuestionTrigger";

const Container = styled.div`
  &.tooltip-container {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.10);
    background: white;
    font-family: Hind,sans-serif;
    border-radius: .25em;
    font-size: 14px;
    font-weight: 400;
    color: ${palette.darkGray};
    line-height: 20px;
    text-align: left;
    width: 290px;
    border: none;
    border-radius: 8px;
    padding: 10px;
  }

  .tooltip-arrow[data-placement*='right']:before {
    border-color: transparent white transparent transparent;
  }
  .tooltip-arrow[data-placement*='left']:before {
    border-color: transparent transparent transparent white;
  }
  .tooltip-arrow[data-placement*='top']:before {
    border-color: white transparent transparent transparent;
  }
  .tooltip-arrow[data-placement*='bottom']:before {
    border-color: transparent transparent white transparent;
  }
`;

type Props = Readonly<{
  children: JSX.Element,
  placement: PopperJS.Placement,
  trigger?: Trigger
}>

const Tooltip = ({children, placement, trigger}: Props) => (
  <TooltipTrigger
    placement={placement}
    trigger={trigger}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement
    }) => (
      <Container
        {...getTooltipProps({
          ref: tooltipRef,
          className: 'tooltip-container'
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            className: 'tooltip-arrow',
            'data-placement': placement
          })}
        />
        {children}
      </Container>
    )}
  >
    {QuestionTrigger}
  </TooltipTrigger>
);

export default Tooltip;
