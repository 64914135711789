
import React from 'react';

import { Icon } from '@iconify/react';

import questionIcon from '@iconify/icons-simple-line-icons/question';

import ExampleLink from '../shared/FormInputLabelIconPopoverContainerExampleLink';
import IconContainer from '../shared/IconContainer';
import IconPopoverContainer from '../shared/FormInputLabelIconPopoverContainer';
import Label from '../shared/FromInputLabel';
import LabelIconContainer from '../shared/FormInputLabelIconContainer';
import Par from '../shared/FormInputLabelIconPopoverContainerPar';
import PopoverContentParagraph from '../shared/FormInputLabelIconPopoverContainerContentPar';
import LabelContainer from '../shared/FormInputLabelContainer';

export default () => (
  <LabelContainer>
    <Label label="Copy your website's FAQ link"></Label>
    <LabelIconContainer>
      <IconContainer><Icon icon={questionIcon}/></IconContainer>
      <IconPopoverContainer>
        <Par>FAQ link Example:</Par>
        <ExampleLink>https://www.mydomain.com/faq.html</ExampleLink>
        <PopoverContentParagraph>You can add one FAQ URL link at a time. To add additional FAQ pages, click <a href='#/' onClick={(e) => e.preventDefault()}>“Add another FAQ link”</a></PopoverContentParagraph>
      </IconPopoverContainer>
    </LabelIconContainer>
  </LabelContainer>
);
