import styled from "styled-components";
import { palette } from "../../styles/variables";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  color: ${palette.pink};
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin-left: 5px;
`;

export const PinkIcon = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  background: ${palette.pink};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;
