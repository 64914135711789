import React from 'react';

import { ThunkDispatch } from 'redux-thunk';

import queryString from 'query-string';

import { OnboardingActionType } from '../../store/onboarding/onboarding.types';

import { verifyEmail } from '../../store/onboarding/onboarding.actions';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import Logger from '../../lib/Logger';
import { Redirect } from 'react-router';

const logger = new Logger('verify-email.index');

interface Props {
  location: any;
  verifyEmail: (emailVerificationId: string) => Promise<void>;
}

const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, OnboardingActionType>) => ({
  verifyEmail: (emailVerificationId: string) => dispatch(verifyEmail(emailVerificationId)),
});

class Component extends React.PureComponent<Props> {
  componentDidMount() {
    const queryParameters = queryString.parse(this.props.location.search);
    logger.log('componentDidMount', 'this.props.location.search', this.props.location.search, 'queryParameters', queryParameters);
    const emailVerificationId = queryParameters.emailVerificationId as string;
    if (emailVerificationId !== undefined && emailVerificationId !== '') {
      this.props.verifyEmail(emailVerificationId);
    }
  }

  render() {
    return (<Redirect to="/app" />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
