import UserBusiness from '../../models/UserBusiness';
import UserInvite from '../../models/UserInvite';
import Fact from '../../lib/Fact';

export const GET_ALL_TEAMMEMBERS_REQUEST = 'GET_ALL_TEAMMEMBERS_REQUEST';
export const GET_ALL_TEAMMEMBERS_SUCCESS = 'GET_ALL_TEAMMEMBERS_SUCCESS';
export const GET_ALL_TEAMMEMBERS_FAILURE = 'GET_ALL_TEAMMEMBERS_FAILURE';

export const GET_ALL_INVITES_REQUEST = 'GET_ALL_INVITES_REQUEST';
export const GET_ALL_INVITES_SUCCESS = 'GET_ALL_INVITES_SUCCESS';
export const GET_ALL_INVITES_FAILURE = 'GET_ALL_INVITES_FAILURE';

export const CREATE_INVITE_REQUEST = 'CREATE_INVITE_REQUEST';
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';
export const CREATE_INVITE_FAILURE = 'CREATE_INVITE_FAILURE';

export const DELETE_TEAMMEMBER_REQUEST = 'DELETE_TEAMMEMBER_REQUEST';
export const DELETE_TEAMMEMBER_SUCCESS = 'DELETE_TEAMMEMBER_SUCCESS';
export const DELETE_TEAMMEMBER_FAILURE = 'DELETE_TEAMMEMBER_FAILURE';

export const UPDATE_TEAMMEMBER_REQUEST = 'UPDATE_TEAMMEMBER_REQUEST';
export const UPDATE_TEAMMEMBER_SUCCESS = 'UPDATE_TEAMMEMBER_SUCCESS';
export const UPDATE_TEAMMEMBER_FAILURE = 'UPDATE_TEAMMEMBER_FAILURE';

export const ACTIVATE_INVITE_REQUEST = 'ACTIVATE_INVITE_REQUEST';
export const ACTIVATE_INVITE_SUCCESS = 'ACTIVATE_INVITE_SUCCESS';
export const ACTIVATE_INVITE_FAILURE = 'ACTIVATE_INVITE_FAILURE';

export const UPDATE_INVITE_REQUEST = ' UPDATE_INVITE_REQUEST';
export const UPDATE_INVITE_SUCCESS = ' UPDATE_INVITE_SUCCESS';
export const UPDATE_INVITE_FAILURE = ' UPDATE_INVITE_FAILURE';

export const GET_USER_INVITE_REQUEST = 'GET_USER_INVITE_REQUEST';
export const GET_USER_INVITE_SUCCESS = 'GET_USER_INVITE_SUCCESS';
export const GET_USER_INVITE_FAILURE = 'GET_USER_INVITE_FAILURE';
export const USER_INVITE_ACCEPTED = 'USER_INVITE_ACCEPTED';
export const RESET_INVITATION_ACCEPTANCE = 'RESET_INVITATION_ACCEPTANCE';
export const SET_INVITATION_ACCEPTANCE = 'SET_INVITATION_ACCEPTANCE';

export type RequestFailureAction = {
  type: string;
};

export type GetAllTeamMembersSuccessAction = {
  type: typeof GET_ALL_TEAMMEMBERS_SUCCESS;
  data: UserBusiness[];
};

export type GetAllInvitesSuccessAction = {
  type: typeof GET_ALL_INVITES_SUCCESS;
  data: UserInvite[];
};

export type DeleteTeamMemberSuccessAction = {
  type: typeof DELETE_TEAMMEMBER_SUCCESS;
  userId: string;
};

export type CreateInviteSuccessAction = {
  type: typeof CREATE_INVITE_SUCCESS;
  data: UserInvite;
};

export type UpdateTeamMemberSuccessAction = {
  type: typeof UPDATE_TEAMMEMBER_SUCCESS;
  data: UserBusiness;
};

export type UpdateInviteSuccessAction = {
  type: typeof UPDATE_INVITE_SUCCESS;
  data: UserInvite;
};

export type GetUserInviteSuccessAction = {
  type: typeof GET_USER_INVITE_SUCCESS;
  data: UserInvite;
};

export type TeamMemberState = {
  users: UserBusiness[];
  invites: UserInvite[];
  userInvite?: UserInvite;
  fact?: Fact;
  hasAcceptedInvitation?: boolean;
};

export type TeamMemberAction =
  | RequestFailureAction
  | GetAllTeamMembersSuccessAction
  | DeleteTeamMemberSuccessAction
  | GetAllInvitesSuccessAction
  | GetUserInviteSuccessAction
  | CreateInviteSuccessAction
  | UpdateTeamMemberSuccessAction
  | UpdateInviteSuccessAction;
