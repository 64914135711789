import React from 'react';
import styled from "styled-components";
import { palette } from '../../styles/variables';

interface TimelineStepProps {
  first?: boolean;
  active?: boolean;
};

const Step = styled.div`
    line-height: 36px;
    width: 40px;
    text-align: center;
    border-radius: 100%;
    background: white;
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    border: 2px solid rgba(151, 151, 151, .2);
    z-index: 6;
    height: 40px;
    color: ${palette.mediumGray};
    text-decoration: none;
    outline: none;
    transition: all 0.5s ease-in-out 0s;
    padding: 0px;
    margin: 0px;
`;

const Title = styled.span`
  position: absolute;
  bottom: -40px;
  color: rgba(151, 151, 151, 1);
  left: auto;
  transform: translate(-55%, 0);
  font-size: 14px;
  right: auto;
  font-weight: 500;
`;

const Container = styled.li<TimelineStepProps>`
  float: left;
  padding-left: ${ props => props.first ? `0px` : `60px` };
  position: relative;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 100%;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 3px;
    z-index: -2;
    border-top: 3px solid hsla(0,0%,59.2%,.2);
    width: 100%;
  }
  
  ${Step} {
    background: ${ props => props.active && palette.blue };
    color: ${ props => props.active && `white` };
    position: relative;

    ${Title}  {
      color: ${ props => props.active && palette.blue };
    }
  }

`;

export default ({active = false, first = false, stepNumber, stepTitle}: {active?: boolean, first?: boolean, stepNumber: string, stepTitle: string}) => (
  <Container active={active} first={first}>
    <Step>{stepNumber} <Title>{stepTitle}</Title></Step>
  </Container>
);
