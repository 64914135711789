import { ThunkDispatch, ThunkAction } from "redux-thunk";
import {
  TeamMemberAction,
  GET_ALL_TEAMMEMBERS_REQUEST,
  GET_ALL_TEAMMEMBERS_SUCCESS,
  GET_ALL_TEAMMEMBERS_FAILURE,
  GET_ALL_INVITES_REQUEST,
  GET_ALL_INVITES_SUCCESS,
  GET_ALL_INVITES_FAILURE,
  CREATE_INVITE_REQUEST,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  DELETE_TEAMMEMBER_REQUEST,
  DELETE_TEAMMEMBER_SUCCESS,
  DELETE_TEAMMEMBER_FAILURE,
  UPDATE_TEAMMEMBER_REQUEST,
  UPDATE_TEAMMEMBER_SUCCESS,
  UPDATE_TEAMMEMBER_FAILURE,
  UPDATE_INVITE_REQUEST,
  UPDATE_INVITE_SUCCESS,
  UPDATE_INVITE_FAILURE,
  GET_USER_INVITE_REQUEST,
  GET_USER_INVITE_SUCCESS,
  GET_USER_INVITE_FAILURE,
  ACTIVATE_INVITE_FAILURE,
  ACTIVATE_INVITE_SUCCESS,
  RESET_INVITATION_ACCEPTANCE,
  SET_INVITATION_ACCEPTANCE,
} from "./team-member.types";
import { adb2c } from "../../lib/ADB2C";
import Utils from "../../lib/Utils";
import APIUtils from "../../lib/APIUtils";
import Logger from "../../lib/Logger";

const logger = new Logger('team-member.actions');

export const getAllTeamMembers = (businessId: string) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: GET_ALL_TEAMMEMBERS_REQUEST
  });

  const conf = {
    path: `/api/teams/${businessId}/members`,
    method: "get"
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: GET_ALL_TEAMMEMBERS_FAILURE
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: GET_ALL_TEAMMEMBERS_SUCCESS,
          data: fetchRes,
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: GET_ALL_TEAMMEMBERS_FAILURE
      });
      logger.log('getAllTeamMembers', error);
      Utils.showRequestFailedWithText(error);
    }
  );
};

export const getAllInvites = (businessId: string) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: GET_ALL_INVITES_REQUEST
  });

  const conf = {
    path: `/api/teams/${businessId}/invites`,
    method: "get"
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: GET_ALL_INVITES_FAILURE
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: GET_ALL_INVITES_SUCCESS,
          data: fetchRes,
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: GET_ALL_INVITES_FAILURE
      });
      logger.log('getAllInvites', error);
    }
  );
};

export const createInvite = (
  businessId: string, firstName: string, lastName: string, emailAddress: string, roleId: number
) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: CREATE_INVITE_REQUEST
  });
  Utils.showInfoLoadingWithText("Creating a team member");
  const conf = {
    path: `/api/teams/${businessId}/invites`,
    method: "post",
    data: {
      businessId,
      firstName,
      lastName,
      emailAddress,
      roleId
    }
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: CREATE_INVITE_FAILURE
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: CREATE_INVITE_SUCCESS,
          data: fetchRes,
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: CREATE_INVITE_FAILURE
      });
      logger.log('createInvite', error);
      Utils.showRequestFailedWithText(error);
    }
  );
};

export const updateTeamMember = (businessId: string, userId: string, roleId: number, isActive: boolean) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: UPDATE_TEAMMEMBER_REQUEST
  });

  Utils.showInfoLoadingWithText('Updating a team member');

  const conf = {
    path: `/api/teams/${businessId}/members`,
    method: "patch",
    data: {
      businessId,
      userId,
      roleId,
      isActive
    }
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: UPDATE_TEAMMEMBER_FAILURE
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: UPDATE_TEAMMEMBER_SUCCESS,
          data: fetchRes,
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: UPDATE_TEAMMEMBER_FAILURE
      });
      logger.log('updateTeamMember', error);
      Utils.showRequestFailedWithText(error);
    }
  );
};

export const deleteTeamMember = (businessId: string, userId: string) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: DELETE_TEAMMEMBER_REQUEST
  });

  Utils.showInfoLoadingWithText('Deleting a team member');

  const conf = {
    path: `/api/teams/${businessId}/members/${userId}`,
    method: "delete"
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: DELETE_TEAMMEMBER_FAILURE
        });
        Utils.showRequestFailedWithText();
        throw fetchRes;
      } else {
        dispatch({
          type: DELETE_TEAMMEMBER_SUCCESS,
          userId: userId,
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: DELETE_TEAMMEMBER_FAILURE
      });
      logger.log('deleteTeamMember', error);
      Utils.showRequestFailedWithText(error);
    }
  );
};

export const updateInvite = (
  businessId: string, code: string, firstName: string, lastName: string, roleId: number, isActive: boolean
) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({
    type: UPDATE_INVITE_REQUEST
  });

  Utils.showInfoLoadingWithText('Updating an invite');

  const conf = {
    path: `/api/teams/${businessId}/invites`,
    method: "patch",
    data: {
      code,
      firstName,
      lastName,
      roleId,
      isActive
    }
  };

  return adb2c.makeAuthenticatedApi(conf)
  .then((fetchRes: any) => {
      if (Utils.isError(fetchRes)) {
        dispatch({
          type: UPDATE_INVITE_FAILURE
        });
        throw fetchRes;
      } else {
        dispatch({
          type: UPDATE_INVITE_SUCCESS,
          data: fetchRes
        });
      }
      Utils.hideLoading();
    },
    (error) => {
      dispatch({
        type: UPDATE_INVITE_FAILURE
      });
      logger.log('updateInvite', error);
      Utils.showRequestFailedWithText(error);
    }
  );
};

export const getUserInvite = (invitationCode: string) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  dispatch({ type: GET_USER_INVITE_REQUEST });

  Utils.showInfoLoadingWithText('Retrieving the invite information');

  return APIUtils.getUserInvite(invitationCode)
          .then((apiRes) => dispatch({type: GET_USER_INVITE_SUCCESS, data: apiRes}))
          .catch(() => dispatch({type: GET_USER_INVITE_FAILURE}))
          .then(() => Utils.hideLoading());
}

export const activateInvitation = (invitationCode: string) => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => {
  return APIUtils.activateInvitation(invitationCode)
        .then(() => {
          dispatch({ type: ACTIVATE_INVITE_SUCCESS });
        })
        .catch(() => dispatch({ type: ACTIVATE_INVITE_FAILURE }))
        .then(() => Utils.hideLoading());
}

export const resetInvitationAcceptance = (): ThunkAction<Promise<void>, {}, {}, TeamMemberAction> => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => new Promise<void>(() => dispatch({type: RESET_INVITATION_ACCEPTANCE}));

export const setInvitationAcceptance = (): ThunkAction<Promise<void>, {}, {}, TeamMemberAction> => (dispatch: ThunkDispatch<{}, {}, TeamMemberAction>) => new Promise<void>(() => dispatch({type: SET_INVITATION_ACCEPTANCE}));

