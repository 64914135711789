import React from "react";
import Button from "../../../shared/buttons/Button";
import Footer from "../../../shared/modals/Footer";
import PageTitle from "../../../shared/PageTitle";
import TextButton from "../../../shared/buttons/TextButton";

interface Props {
  acceptButtonLabel?: string;
  textButtonLabel?: string;
  title: string;
  onAcceptClick: () => void;
  onTexButtonClick?: () => void;
}

export default ({acceptButtonLabel = 'Ok', textButtonLabel, title, onAcceptClick, onTexButtonClick}: Props) => (
  <>
    <PageTitle title={title} />
    <Footer>
      { textButtonLabel &&  <TextButton onClick={onTexButtonClick}>{ textButtonLabel }</TextButton> }
      <Button onClick={onAcceptClick}>{acceptButtonLabel}</Button>
    </Footer>
  </>
);
