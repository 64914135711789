import {
    PlatformInformationState,
    PlatformInformationAction,
    UPDATE_PLATFORM_INFORMATION_REQUEST,
    UPDATE_PLATFORM_INFORMATION_SUCCESS,
    UPDATE_PLATFORM_INFORMATION_FAILURE,
    UpdatePlatformInformationFailureAction,
} from './platform-information.types';
import Fact from '../../../lib/Fact';

const initialState: PlatformInformationState = {
    isUpdatingPlatformInformation: false,
    fact: undefined,
    logoFile: undefined,
    longDescription: '',
    shortDescription: '',
    privacyPolicyUrl: '',
    termsOfUseUrl: '',
};

const platformInformationReducer = (state: PlatformInformationState = initialState, action: PlatformInformationAction): PlatformInformationState => {
    switch (action.type) {
        case UPDATE_PLATFORM_INFORMATION_REQUEST:
            return {
                ...state,
                isUpdatingPlatformInformation: true,
            };
        case UPDATE_PLATFORM_INFORMATION_SUCCESS:
            return {
                ...state,
                isUpdatingPlatformInformation: false,
                fact: new Fact(UPDATE_PLATFORM_INFORMATION_SUCCESS),
            }
        case UPDATE_PLATFORM_INFORMATION_FAILURE:
            return {
                ...state,
                isUpdatingPlatformInformation: false,
                fact: new Fact(UPDATE_PLATFORM_INFORMATION_FAILURE, (action as UpdatePlatformInformationFailureAction).reason),
            };
        default:
            return state;
    }
};

export { platformInformationReducer };
