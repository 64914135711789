import styled from "styled-components"

export default styled.div`
  background-color: rgba(255, 0, 116, 0.4);;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
`;

