import {
  GetIndustriesState,
  GetIndustriesActionType,
  GetIndustriesSuccessAction,
  GET_INDUSTRIES_REQUEST,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
  GetIndustriesFailureAction
} from './get-industries.types';
import Fact from '../../lib/Fact';

const intialState: GetIndustriesState = {
  industries: [],
  isGettingIndustries: false,
  hasLoaded: false,
  fact: undefined,
};

export const getIndustriesReducer = (state: GetIndustriesState = intialState, action: GetIndustriesActionType): GetIndustriesState => {
  switch (action.type) {
    case GET_INDUSTRIES_REQUEST:
      return {
        ...state,
        isGettingIndustries: true,
      };
    case GET_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: (action as GetIndustriesSuccessAction).industries,
        isGettingIndustries: false,
        hasLoaded: true,
        fact: new Fact(GET_INDUSTRIES_SUCCESS),
      };
    case GET_INDUSTRIES_FAILURE:
      return {
        ...state,
        isGettingIndustries: false,
        hasLoaded: false,
        fact: new Fact(GET_INDUSTRIES_FAILURE, (action as GetIndustriesFailureAction).reason),
      };
    default:
      return state;
  }
};

