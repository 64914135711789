import React, { ReactNode } from "react";
import styled from "styled-components";
import { palette } from "../../styles/variables";
import { Link } from "react-router-dom";
import TooltipContainer from "../../shared/TooltipContainer";

const Container = styled(Link)`
  border: 1px solid ${palette.lightGray};
  border-radius: 4px;
  text-align: center;
  padding: 18px 23px 45px;
  transition: all ease-in-out 0.5s;
  height: 100%;
  margin: 0 -10px;
  cursor: pointer;
  display: block;
  &:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.5s;
  }
`;

const Heading = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 19px;

  a {
    color: ${palette.blue};
    font-weight: 500;
    font-size: 12px;
  }
`;

const Icon = styled.div`
  svg {
    font-size: 48px;
    color: ${palette.blue};
    display: block;
    margin: 0 auto 25px;
  }
`;

const H4 = styled.h4`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 5px;
  a {
    font-weight: 500;
    &:hover {
      color: ${palette.blue};
    }
  }
`;

const P = styled.p`
  font-size: 14px;
  color: ${palette.lightBlack};
  line-height: 16px;
`;

const Pill = styled.span`
  color: ${palette.black};
  border-radius: 10px;
  font-size: 12px;
  background-color: ${palette.border};
  font-weight: 500;
  padding: 3px 11px;
  &:hover {
    background-color: ${palette.blue};
    color: white;
  }
`

interface Props {
  icon: ReactNode;
  title: string;
  description: string;
  to: string;
  steps?: number;
  completed?: number;
  tooltipDescComponent?: JSX.Element;
}

export default ({ icon, title, description, to, steps, completed, tooltipDescComponent }: Props) => {
  return (
    <Container to={to}>
      <Heading>
        {tooltipDescComponent && <TooltipContainer
          trigger={(
            <Pill>
              {completed}/{steps}
            </Pill>
          )}
        >
          {tooltipDescComponent}
        </TooltipContainer>}
      </Heading>
      <Icon>{icon}</Icon>
      <H4>{title}</H4>
      <P>{description}</P>
    </Container>
  );
};
