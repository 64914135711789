import React from "react"
import styled from "styled-components"
import { palette } from "../../styles/variables"

interface Props {
  title: string,
  description?: string | JSX.Element,
  align?: string,
}

interface ContainerProps {
  align: string,
}

const Container = styled.div<ContainerProps>`
  margin-bottom: 20px;
  ${props => props.align === 'center' && `
    text-align: center;
  `}
  a {
    color: ${palette.blue};
    &:hover {
      color: ${palette.black};
    }
  }
`

interface H2Props {
  space: boolean,
}
const H2 = styled.h2<H2Props>`
  margin-bottom: ${props => props.space ? '10px' : '0'};
`

export default ({title, description, align = 'left', ...rest}: Props) => (
  <Container align={align} {...rest}>
    <H2 space={description ? true : false}>{title}</H2>
    {description && <>{description}</>}
  </Container>
)
