import {
  TeamMemberState,
  TeamMemberAction,
  GET_ALL_TEAMMEMBERS_SUCCESS,
  GET_ALL_INVITES_SUCCESS,
  DELETE_TEAMMEMBER_SUCCESS,
  UPDATE_TEAMMEMBER_SUCCESS,
  GetAllInvitesSuccessAction,
  GetAllTeamMembersSuccessAction,
  DeleteTeamMemberSuccessAction,
  CREATE_INVITE_SUCCESS,
  CreateInviteSuccessAction,
  UpdateTeamMemberSuccessAction,
  UPDATE_INVITE_SUCCESS,
  UpdateInviteSuccessAction,
  GET_USER_INVITE_SUCCESS,
  GetUserInviteSuccessAction,
  GET_USER_INVITE_FAILURE,
  ACTIVATE_INVITE_SUCCESS,
  ACTIVATE_INVITE_FAILURE,
  USER_INVITE_ACCEPTED,
  RESET_INVITATION_ACCEPTANCE,
  SET_INVITATION_ACCEPTANCE
} from "./team-member.types";
import Fact from "../../lib/Fact";
import UserInvite from "../../models/UserInvite";

const initialState: TeamMemberState = {
  users: [],
  invites: [],
  hasAcceptedInvitation: false,
};

const teamMemberReducer = (
  state: TeamMemberState = initialState,
  action: TeamMemberAction,
): TeamMemberState => {
  switch (action.type) {
    case UPDATE_INVITE_SUCCESS: {
      const successAction = action as UpdateInviteSuccessAction;
      
      return {
        ...state,
        invites: state.invites.map(invite => invite.code === successAction.data.code ? successAction.data : invite)
      };
    }
    case UPDATE_TEAMMEMBER_SUCCESS: {
      const successAction = action as UpdateTeamMemberSuccessAction;
      
      return {
        ...state,
        users: state.users.map(user => user.userId === successAction.data.userId ? successAction.data : user)
      };
    }
    case CREATE_INVITE_SUCCESS: {
      const successAction = action as CreateInviteSuccessAction;
      return {
        ...state,
        invites: [
          ...state.invites,
          successAction.data
        ]
      };
    }
    case GET_ALL_TEAMMEMBERS_SUCCESS: {
      const successAction = action as GetAllTeamMembersSuccessAction;
      return {
        ...state,
        users: successAction.data
      };
    }
    case DELETE_TEAMMEMBER_SUCCESS: {
      const successAction = action as DeleteTeamMemberSuccessAction;
      return {
        ...state,
        users: state.users.filter(user => user.userId !== successAction.userId)
      }
    }
    case GET_ALL_INVITES_SUCCESS: {
      const successAction = action as GetAllInvitesSuccessAction;
      return {
        ...state,
        invites: successAction.data
      };
    }
    case GET_USER_INVITE_SUCCESS: {
      const successAction = action as GetUserInviteSuccessAction;
      const userInvite = successAction.data as UserInvite;

      return {
        ...state,
        userInvite: userInvite,
        fact: (userInvite.isActive) ? undefined : new Fact(USER_INVITE_ACCEPTED),
      }
    }
    case GET_USER_INVITE_FAILURE: {
      return {
        ...state,
        fact: new Fact(GET_USER_INVITE_FAILURE),
      }
    }
    case ACTIVATE_INVITE_SUCCESS: {
      return {
        ...state,
        fact: new Fact(ACTIVATE_INVITE_SUCCESS),
      }
    }
    case ACTIVATE_INVITE_FAILURE: {
      return {
        ...state,
        fact: new Fact(ACTIVATE_INVITE_FAILURE),
      }
    }
    case SET_INVITATION_ACCEPTANCE: {
      return {
        ...state,
        hasAcceptedInvitation: true,
      }      
    }
    case RESET_INVITATION_ACCEPTANCE: {
      return {
        ...state,
        hasAcceptedInvitation: false,
      }
    }
    default:
      return state;
  }
};

export default teamMemberReducer;
