import React from "react";
import { Icon } from "@iconify/react";
import controlPlay from "@iconify/icons-simple-line-icons/control-play";
import questionIcon from "@iconify/icons-simple-line-icons/question";
import { Container, Button, PinkIcon } from "./styles";
import TooltipContainer from "../TooltipContainer";

interface Props {
  onClick: () => void;
}

export default ({ onClick }: Props) => (
  <Container>
    <TooltipContainer trigger={<Icon icon={questionIcon} />} tooltipPosition="left">
      <div>This instructional video will explain what Zammo can do with and for you on this screen. You can hide these by navigating to My Profile and unselect instructional videos under My Profile Settings.</div>
    </TooltipContainer>
    <Button onClick={onClick}>
      Play Screen Instructional Video{" "}
      <PinkIcon>
        <Icon icon={controlPlay} />
      </PinkIcon>
    </Button>
  </Container>
);
