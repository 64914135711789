import Utils from './Utils';

export default class Fact {
    description: string;
    uid: string;
    message: string;

    constructor(description: string, message: string = '') {
        this.description = description;
        this.uid = Utils.uuidv4();
        this.message = message;
    }

    isEqual(anotherFact: Fact | undefined): boolean {
        return (
            anotherFact !== undefined
            && anotherFact.description === this.description
            && anotherFact.uid === this.uid
        );
    }
};
