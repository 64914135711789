import Fact from "../../../lib/Fact";

export const UPDATE_LONG_DESCRIPTION: string = 'UPDATE_LONG_DESCRIPTION';
export const UPDATE_LOGO_FILE: string = 'UPDATE_LOGO_FILE';
export const UPDATE_PRIVACY_POLICY_URL: string = 'UPDATE_PRIVACY_POLICY_URL';
export const UPDATE_SHORT_DESCRIPTION: string = 'UPDATE_SHORT_DESCRIPTION';
export const UPDATE_TERMS_OF_USE_URL: string = 'UPDATE_TERMS_OF_USE_URL';
export const UPDATE_PLATFORM_INFORMATION_REQUEST: string = 'UPDATE_PLATFORM_INFORMATION_REQUEST';
export const UPDATE_PLATFORM_INFORMATION_SUCCESS: string = 'UPDATE_PLATFORM_INFORMATION_SUCCESS';
export const UPDATE_PLATFORM_INFORMATION_FAILURE: string = 'UPDATE_PLATFORM_INFORMATION_FAILURE';

export type PlatformInformationState = {
    logoFile: File | undefined;
    logoUrl?: string;
    longDescription: string;
    privacyPolicyUrl: string;
    shortDescription: string;
    termsOfUseUrl: string;
    isUpdatingPlatformInformation: boolean;
    fact: Fact | undefined;
};

export type UpdateLogoFileAction = {
    type: typeof UPDATE_LOGO_FILE;
    logoFile: File;
};

export type UpdateLongDescriptionAction = {
    type: typeof UPDATE_LONG_DESCRIPTION;
    longDescription: string;
};

export type UpdatePrivacyPolicyUrlAction = {
    type: typeof UPDATE_PRIVACY_POLICY_URL;
    privacyPolicyUrl: string;
};

export type UpdateShortDescriptionAction = {
    type: typeof UPDATE_SHORT_DESCRIPTION;
    shortDescription: string;
};

export type UpdateTermsOfUseUrlAction = {
    type: typeof UPDATE_TERMS_OF_USE_URL;
    termsOfUseUrl: string;
};

export type UpdatePlatformInformationRequestAction = {
    type: typeof UPDATE_PLATFORM_INFORMATION_REQUEST;
};

export type UpdatePlatformInformationSuccessAction = {
    type: typeof UPDATE_PLATFORM_INFORMATION_SUCCESS;
};

export type UpdatePlatformInformationFailureAction = {
    type: typeof UPDATE_PLATFORM_INFORMATION_FAILURE;
    reason: string;
};

export type PlatformInformationAction = 
    | UpdatePlatformInformationRequestAction
    | UpdatePlatformInformationSuccessAction
    | UpdatePlatformInformationFailureAction
