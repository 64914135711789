import styled from 'styled-components';
import media from "../../../styles/media";
import { palette } from '../../../styles/variables';

export default styled.div`
  position: absolute;
  bottom: auto;
  top: -16px;
  z-index: 4;
  right: -100%;
  left: 100%;
  box-shadow: 0 0 14px 0 rgba(0,0,0,.1);
  background: white;
  font-family: Hind,sans-serif;
  border-radius: .25em;
  font-size: 14px;
  font-weight: 400;
  color: ${palette.darkGray};
  padding: 8px 15px;
  line-height: 20px;
  margin-left: 12px;
  text-align: left;
  visibility: hidden;
  width: 290px;
  opacity: 0;
  padding: 15px 25px 20px 20px;
  transition: all .1s ease-in-out;

  &:hover {
    cursor: default;
    visibility: visible;
    opacity: 1;
  }

  &:before {
    content: "";
    border-top: 8px solid transparent;
    border-right: 10px solid white;
    border-bottom: 8px solid transparent;
    left: -10px;
    position: absolute;
  }

  ${media.xl`
    left: auto;
    right: 100%;
    margin-right: 12px;
    &:before {
      border-right: none;
      border-left: 10px solid white;
      left: auto;
      right: -10px;
    }
  `}

`;
