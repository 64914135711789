import { connect } from 'react-redux';
import pathOr from 'ramda/src/pathOr';
import DescriptionInput from './DescriptionInput';

import { AppState } from '../../../store';

import { updateLongDescription } from '../../../store/business/business.actions';

const mapStateToProps = (state: AppState) => ({
  description: pathOr('', ['businessReducer', 'business', 'longDescription'], state) as string,
  label: 'Long Description',
  textareaPlaceholder:
    'Our voice app lets you learn more about our organization. Ask frequently asked questions about us and the voice assistant will happily answer them.',
  popoverText:
    'This is a long description of what your voice app offers to your users.  It is seen only by the voice platforms. We have prepopulated this field and we suggest you keep it this way without changing anything.',
  textareaCharacterLimit: 512,
});

const mapDispatchToProps = {
  updateTextArea: updateLongDescription,
};

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionInput);
