import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import arrowDown from "@iconify/icons-simple-line-icons/arrow-down";
import styled from "styled-components";

import DropDown from "../shared/DropDown";
import DropDownItem from "../shared/DropDownItem";

import media from "../../../../styles/media";

import { palette } from "../../../../styles/variables";
import { setIsOpenAddOrganizationModal } from "../../../../store/app/app.actions";


const Container = styled.div`
  min-width: 181px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  cursor: pointer;
  position: relative;

  &:hover {
    ${DropDown} {
      visibility: visible;
      opacity: 1;
      transition: all ease-in-out 0.5s;
      margin-top: -10px;
    }
  }

  ${media.md`
    min-width: 160px;
  `}
  ${media.xsm`
    min-width: unset;
    font-size: 12px;
  `}
`;

const IconContainer = styled.span`
  margin-left: 8px;
  color: ${palette.blue};
  font-size: 18px;
  display: inline-block;
  font-weight: 700;
  vertical-align: middle;
  ${media.md`
    font-size: 15px;
    margin-left: 10px;
  `}
  ${media.sm`
    display: none;
  `}
  ${media.xsm`
    font-size: 10px;
    margin-left: 0;
    margin-right: 4px;
  `}
`;

const Button = styled.span`
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: -0.2px;
  line-height: 72px;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  ${media.xlg`
    line-height: 65px;
  `}
  ${media.md`
    line-height: 50px;
  `}
`;

const ButtonItem = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 500;
  padding: 0;
  margin: 0;
  &:hover {
    color: ${palette.blue};
  }
`;

export default () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Button>Organizations</Button>
      <IconContainer>
        <Icon icon={arrowDown} />
      </IconContainer>
      <DropDown>
        <DropDownItem>
          <Link to="/app/organizations">My Organizations</Link>
        </DropDownItem>
        <DropDownItem>
          <ButtonItem onClick={() => dispatch(setIsOpenAddOrganizationModal(true))}>
            Add an Organization
          </ButtonItem>
        </DropDownItem>
      </DropDown>
    </Container>
  );
};
