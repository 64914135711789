import React from 'react';
import styled from 'styled-components';
import { palette } from '../../styles/variables';

const PreviewDiv = styled.div`
  position: absolute;
  background-color: white;
  padding: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center;
  border: 2px solid ${palette.border};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const PreviewInfoDiv = styled.div`
  &:hover {
    opacity: 1;
  }

  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.7);
  transition: opacity .15s linear;
`;

const RemoveButton = styled.button`
  &:hover {
    background: rgba(255,255,255,.2);
  }

  display: block;
  position: absolute;
  opacity: 1;
  z-index: 7;
  top: 10px;
  right: 10px;
  background: 0 0;
  border: 2px solid white;
  text-transform: uppercase;
  font-size: 11px;
  padding: 4px 8px;
  font-weight: 700;
  color: white;
  -webkit-transition: all .15s linear;
  transition: all .15s linear;
  font-family: Roboto,"Helvetica Neue",Helvetica,Arial;
`;

type Props = {
  file: File;
  onRemove: () => void;
};

export default ({ file, onRemove }: Props) => (
  <PreviewDiv>
    <span>{file.name}</span>
    <PreviewInfoDiv>
      <RemoveButton onClick={onRemove}>Remove</RemoveButton>
    </PreviewInfoDiv>
  </PreviewDiv>
);
