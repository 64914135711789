import {
  ConversationalModuleState,
  ConversationalModuleActions,
  POST_CONNECT_CONVERSATIONAL_MODULE_REQUEST,
  GetConnectionsConversationalModuleSuccessAction,
  GET_CONNECTIONS_CONVERSATIONAL_MODULE_SUCCESS,
  GET_CONVERSATIONAL_METADATA_SUCCESS,
  GetConversationMetadataSuccessAction
} from "./conversational-modules.types";

const initialState: ConversationalModuleState = {
  connections: [],
  currentModuleConfig: {
    moduleName: "",
    businessId: "",
    connectionId: "",
    configuration: {
      azureAdAppId: "",
      azureAdAppSecret: "",
      meetingRoomIdentity: "",
      azureTenantId: ""
    }
  }
};

const conversationalModuleReducer = (
  state: ConversationalModuleState = initialState,
  action: ConversationalModuleActions
): ConversationalModuleState => {
  switch (action.type) {
    case POST_CONNECT_CONVERSATIONAL_MODULE_REQUEST: {
      return {
        ...state
      };
    }
    case GET_CONNECTIONS_CONVERSATIONAL_MODULE_SUCCESS: {
      return {
        ...state,
        connections: (action as GetConnectionsConversationalModuleSuccessAction)
          .connections
      };
    }
    case GET_CONVERSATIONAL_METADATA_SUCCESS: {
      return {
            ...state,
            currentModuleConfig: (action as GetConversationMetadataSuccessAction)
              .currentModuleConfig
          };
        // : state;
    }
    default:
      return state;
  }
};

export default conversationalModuleReducer;
