import React from "react"
import styled from "styled-components"
import Modal from 'react-bootstrap/Modal'
import { Icon } from '@iconify/react';
import exclamationIcon from '@iconify/icons-simple-line-icons/exclamation';
import L from "../../shared/Label";
import Button from "../../shared/buttons/Button";
import { palette } from "../../styles/variables";

const Container = styled.div`
  position: relative;
  padding: 74px 36px 20px;
  text-align: center;

  svg {
    font-size: 80px;
    color: ${palette.blue};
    margin-bottom: 40px;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  span {
    font-size: 60px;
    font-weight: 100;
    min-height: 1px;
    line-height: 20px;
  }
`

const Footer = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SaveButton = styled(Button)`
  font-size: 16px;
  text-transform: uppercase;
`

const Label = styled(L)`
  font-size: 30px;
  margin-bottom: 40px;
`

interface Props {
  show: boolean,
  onClose: () => void,
}
export default ({show, onClose}: Props) => {
  return (
    <Modal centered show={show} onHide={onClose}>
      <Container>
        <CloseButton type="button" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </CloseButton>
        <Icon icon={exclamationIcon} />
        <Label>There are no questions to review.</Label>
        <Footer>
          <SaveButton onClick={onClose}>Close</SaveButton>
        </Footer>
      </Container>
    </Modal>
  )
}