import Fact from "../../../lib/Fact";
import { EnterpriseQna } from "./manage-enterprise-qnas.types";

export const GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST: string = 'GET_ENTERPRISE_QNA';
export const GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS: string = 'GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS';
export const GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE: string = 'GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE';

export type GetEnterpriseIndustryQnasState = {
    enterpriseQnas: EnterpriseQna[];
    isGettingEnterpriseIndustryQnas: boolean;
    fact: Fact | undefined;
};

export type GetEnterpriseIndustryQnasRequestAction = {
    type: typeof GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST;
};

export type GetEnterpriseIndustryQnasSuccessAction = {
    type: typeof GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS;
    enterpriseQnas: EnterpriseQna[];
};

export type GetEnterpriseIndustryQnasFailureAction = {
    type: typeof GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE;
    reason: string;
};

export type GetEnterpriseIndustryQnasAction =
GetEnterpriseIndustryQnasRequestAction
| GetEnterpriseIndustryQnasSuccessAction
| GetEnterpriseIndustryQnasFailureAction;
