import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import arrowRight from '@iconify/icons-simple-line-icons/arrow-right';
import trashIcon from '@iconify/icons-simple-line-icons/trash';
import docsIcon from '@iconify/icons-simple-line-icons/docs';
import { Icon } from '@iconify/react';
import { getActiveKeys, generateAPIKey, revokeAPIKey } from '../../store/api-access/api-access.actions';
import { selectBusinessId } from '../../store/business/business.reducers';
import { palette } from '../../styles/variables';
import Button from '../../shared/buttons/Button';
import { Link } from 'react-router-dom';
import PageTitle from '../../shared/PageTitle';
import Utils from '../../lib/Utils';
import { Tooltip } from './styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import APIKeyCreatedModal from './APIKeyCreatedModal';
import { GenerateKeyDTO } from '../../store/api-access/api-access.types';

const Table = styled.table`
  width: 100%;

  tr {
    border-bottom: 1px solid ${palette.border};
  }

  td {
    padding: 0.5rem 0;
  }

  b {
    font-weight: 500;
  }

  tbody {
    td {
      padding: 1rem 0;
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const ArrowIconRight = styled(Icon)`
  margin-left: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const IconButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
`;

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

const CopyAppIdContainer = styled.div`
  margin-left: 2%;
`;

interface InputProps {
  value: string;
}

const CopyAppId = ({ value }: InputProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };
  return (
    <CopyAppIdContainer>
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <TooltipContainer>
          <Icon icon={docsIcon} />
          <Tooltip copied={copied}>{copied ? 'Copied' : 'Copy'} App ID to clipboard</Tooltip>
        </TooltipContainer>
      </CopyToClipboard>
    </CopyAppIdContainer>
  );
};

const AnalyticsAPIKeys = () => {
  const businessId = useSelector(selectBusinessId);
  const [showCreatedModal, setShowCreatedModal] = useState(true);
  const [keyIds, setKeyIds] = useState<string[]>([]);
  const [newAPIKey, setNewAPIKey] = useState<GenerateKeyDTO>();

  const fetchActiveKeys = useCallback(() => {
    getActiveKeys(businessId).then(({ keyIds }) => {
      setKeyIds(keyIds);
    });
  }, [businessId]);

  useEffect(() => {
    fetchActiveKeys();
  }, [fetchActiveKeys]);

  const handleCreateNewKey = () => {
    Utils.showInfoLoadingWithText('Creating a new API key...');
    generateAPIKey(businessId)
      .then((data) => {
        setNewAPIKey(data);
        setShowCreatedModal(true);
        fetchActiveKeys();
        Utils.hideLoading();
      })
      .catch((errorMessage: string) => {
        Utils.showRequestFailedWithText(errorMessage);
      });
  };

  const handleRevoke = (appId: string) => {
    Utils.showConfirmPopup({
      title: '',
      text: 'Are you sure to revoke this API Key?',
      reverseButtons: true,
    }).then(({ value }) => {
      if (value) {
        Utils.showInfoLoadingWithText('Revoking...');
        revokeAPIKey(businessId, appId)
          .then(() => {
            Utils.showModalTypeWithText('The API key has been revoked successfully!', 'success');
            setKeyIds((keyIds) => keyIds.filter((id) => id !== appId));
          })
          .catch(() => {
            Utils.showRequestFailedWithText();
          });
      }
    });
  };

  return (
    <>
      <PageTitle
        title="API Keys"
        description={
          <>
            An API Key that the application uses to prove its identity when requesting a token.&nbsp;
            <Link to="/doc/api" target="_blank">
              This API&nbsp;
            </Link>
            allows you access to retrieve your customer data from Zammo.
          </>
        }
      />
      <Table>
        <thead>
          <tr>
            <td>
              <b>API Key</b>
            </td>
            <td>
              <b>App ID</b>
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {keyIds.map((id) => (
            <tr key={id}>
              <td>
                <div>****************************</div>
              </td>
              <td>
                <div>
                  {id} <CopyAppId value={id} />
                </div>
              </td>
              <td>
                <div>
                  <TooltipContainer>
                    <IconButton onClick={() => handleRevoke(id)} aria-label="icon-button">
                      <Icon icon={trashIcon} />
                    </IconButton>
                    <Tooltip>Revoke Access to Existing API Key</Tooltip>
                  </TooltipContainer>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Footer>
        <Button onClick={handleCreateNewKey}>
          Create a New API Key <ArrowIconRight icon={arrowRight} />
        </Button>
      </Footer>
      {showCreatedModal && newAPIKey && (
        <APIKeyCreatedModal title="API Key Created" onHide={() => setShowCreatedModal(false)} data={newAPIKey} />
      )}
    </>
  );
};

export default AnalyticsAPIKeys;
