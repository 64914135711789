import React from "react"
import styled from "styled-components"
import Breadcrumb from "../../shared/breadcrumb";
import C from "../../shared/layouts/Container";
import QuickLinks from "./QuickLinks";
import media from "../../styles/media";
import Account from "./Account";

const Container = styled(C)`
  ${media.md`
    display: block;
  `}
`

const QuickLinksContainer = styled.div`
  flex: 1;
`

const AccountContainer = styled.div`
  width: 300px;
  margin-left: 66px;
  ${media.md`
    width: 100%;
    margin: 0;
  `}
`

const SectionTitle = styled.h2`
  margin-bottom: 30px;  
`

const AccountTitle = styled(SectionTitle)`
  ${media.md`
    margin-top: 50px;
  `}
`

export default () => (
  <>
    <Breadcrumb path="dashboard" />
    <Container>
      <QuickLinksContainer>
        <SectionTitle>Quicklinks</SectionTitle>
        <QuickLinks />
      </QuickLinksContainer>
      <AccountContainer>
        <AccountTitle>Account Setup</AccountTitle>
        <Account />
      </AccountContainer>
    </Container>
  </>
)