import styled from "styled-components";
import { palette } from "../../styles/variables";

export const Box = styled.div`
  border: 1px solid ${palette.border};
  border-radius: 4px;
  padding: 1rem;
  height: 100%;
  position: relative;
`

export const Label = styled.div`
  font-size: small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${palette.border};
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
