import { ThunkDispatch } from "redux-thunk";
import {
    GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST,
    GetEnterpriseIndustryQnasAction,
    GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS,
    GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE,
} from "./get-enterprise-industry-qnas.types";
import { adb2c } from "../../../lib/ADB2C";
import Logger from '../../../lib/Logger';
import { EnterpriseQna } from "./manage-enterprise-qnas.types";
import Utils from "../../../lib/Utils";

const logger = new Logger('get-enterprise-industry-qnas.actions');

const getEnterpriseIndustryQnasRequest = (): GetEnterpriseIndustryQnasAction => ({
    type: GET_ENTERPRISE_INDUSTRY_QNAS_REQUEST,
});

const getEnterpriseIndustryQnasSuccess = (enterpriseQnas: EnterpriseQna[]): GetEnterpriseIndustryQnasAction => ({
    type: GET_ENTERPRISE_INDUSTRY_QNAS_SUCCESS,
    enterpriseQnas: enterpriseQnas,
});

const getEnterpriseIndustryQnasFailure = (reason: string): GetEnterpriseIndustryQnasAction => ({
    type: GET_ENTERPRISE_INDUSTRY_QNAS_FAILURE,
    reason: reason
});

export const getEnterpriseIndustryQnas = (industryId: string, language: string = 'en', country: string = 'US') => (dispatch: ThunkDispatch<{}, {}, GetEnterpriseIndustryQnasAction>) => {
        logger.log('getEnterpriseIndustryQnas::', 'fetchError', industryId);
        dispatch(getEnterpriseIndustryQnasRequest());
            const hellojsApiConf = {
                'path': `/api/industries/questions/${industryId}?language=${language}&country=${country}`,
                'method': 'get',
            };

            adb2c.makeAuthenticatedApi(hellojsApiConf)
            .then(
                (fetchRes: any) => {
                    logger.log('getEnterpriseIndustryQnas::', 'fetchRes', fetchRes);
                    if (Utils.isError(fetchRes)) {
                        dispatch(getEnterpriseIndustryQnasFailure(fetchRes.message));
                    } else {
                        const industryQnas: EnterpriseQna[] = fetchRes.map((res: any) => ({ id: res.id, question: res.text, isIndustry: true, answer: '' }));
                        logger.log('getEnterpriseIndustryQnas::', 'Sending industries qnas', industryQnas);
                        dispatch(getEnterpriseIndustryQnasSuccess(industryQnas));
                    }
                },
                (fetchError: any) => {
                    logger.log('getEnterpriseIndustryQnas::', 'fetchError', fetchError);
                    dispatch(getEnterpriseIndustryQnasFailure('Could not retrieve '));
                }
            );
    };
