import { connect } from "react-redux";
import pathOr from 'ramda/src/pathOr';
import LinkInput from './LinkInput';
import { AppState } from '../../../store';

import { updateTermsOfUseUrl } from '../../../store/business/business.actions';

const mapStateToProps = (state: AppState) => ({
  label: 'Enter your organization\'s Terms & Conditions link from your website',
  popoverContentParagraph: 'Please enter your website’s terms and conditions link (typically found at the bottom of your website). If you have multiple terms and conditions pages, just select the single link you want to place here. If you do not have terms and conditions, you will need to write them, create the webpage and place the link here.',
  termsOrPrivacy: 'terms',
  url: pathOr('', ['businessReducer', 'business', 'termsOfUseUrl'], state) as string,
});

const mapDispatchToProps = {
  updateUrl: updateTermsOfUseUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkInput);
